import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function MyGraph(props) {

	const { graphOptions } = props
	const options = {style: 'currency', currency: 'USD'};
	const numberFormat = new Intl.NumberFormat('en-US', options);
	
  const MyStockChart = () => 
    <HighchartsReact
      highcharts={Highcharts}
      options={configPrice}
    />

	const configPrice = {
      
    yAxis: [{
      title: {
        text: graphOptions.series[0].name,
        style: {
          fontSize: "16px"
        }
      },
      labels: {
        enabled: graphOptions.labels.enabled,
        formatter: function () {
          return numberFormat.format(this.value) 
        }
        ,
        x: -15,
        style: {
          "color": "#000", "position": "absolute"

        },
        align: 'center'
      },
    },
      
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        return numberFormat.format(this.y, 0) +  '</b><br/>' + this.x
      }
    },
    
    title: {
      text: graphOptions.title.text
    },


    chart: {
      height: graphOptions?.chart?.height ?? null,
      width: graphOptions?.chart?.width ?? null
    },

    credits: {
      enabled: false
    },

    legend: {
      enabled: graphOptions.legend.enabled
    },

    scrollbar: {
      enabled: false
    },
    
    series: [{
      name: graphOptions.series[0].name,
      type: 'column',
      data: graphOptions.series[0].data,
      tooltip: {
        valueDecimals: 2
      },

    }
    ]
  };

    return (
	    <div className ='graph'>
        <MyStockChart />
	    </div>
	)
}

export default MyGraph
