import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import { getBucks } from "../../../_actions/user_actions";

function BucksPage(props) {
  const dispatch = useDispatch();
  const BucksData = props.data.user?.bucks ?? []
  const UBuck = BucksData.filter(function(value, index, arr){ 
	    return value.name === "UBuck"
	})
  console.log(UBuck[0])
  const configureTable = {
    onClick: (group) => {

    },
    tableHeadings: [
      'name', 'ratio'
    ],
  }

  const updatedBucksData = BucksData.map((row, index) => {
    let newRow = {...row}
    newRow.ratio = (row.ratio).toLocaleString('en', {useGrouping:true})
    return newRow
  })

  useEffect(() => {
  	 dispatch(getBucks())
  }, [dispatch])
  
  return (
    <div className='body'>
      <div className='innerBody'>
        <h1>Bucks</h1>
        <Table rowData={updatedBucksData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(BucksPage))