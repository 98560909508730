import React from "react";

function PrivacyPolicyPage() {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-title">Privacy Policy</h1>
      <p className="privacy-date">Effective Date: October 26, 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          This Privacy Policy outlines how SchoolBucks collects, uses, and protects the personal information of its users.
        </p>
      </section>

      <section>
        <h2>2. Information Collection</h2>
        <p>
          We collect the following personal information:
        </p>
        <ul>
          <li>
            For Staff Members: First and last name, email address.
          </li>
          <li>For Students: First name and last initial.</li>
        </ul>
        <p>This information is collected through forms on our website.</p>
      </section>

      <section>
        <h2>3. Information Use</h2>
        <p>We use the collected information to:</p>
        <ul>
          <li>Provide access to educational resources and services.</li>
          <li>Maintain a secure and functional website.</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Security</h2>
        <p>
          We implement reasonable security measures to protect user data from unauthorized access, disclosure, alteration, or destruction. These measures include encrypting passwords and limiting access to authorized users.
        </p>
      </section>

      <section>
        <h2>5. Children's Privacy</h2>
        <p>
          We are committed to protecting the privacy of children. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it.
        </p>
      </section>

      <section>
        <h2>6. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify users of any significant changes by posting a revised version on our website.
        </p>
      </section>

      <section>
        <h2>7. Contact Information</h2>
        <p>
          If you have any questions about this Privacy Policy or our practices, please contact us at:
          <a href="mailto:SchoolBucks@proton.me"> SchoolBucks@proton.me</a>
        </p>
      </section>

      <p className="privacy-consent">By using our website, you consent to the terms of this Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicyPage;
