import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import * as Yup from 'yup';
import { Form, Input, Button, Row, Col, Popconfirm } from 'antd';
import { Formik } from 'formik';
import { saveEdit } from "../../../_actions/user_actions";
import { getClasses, postClass, removeClass } from "../../../_actions/user_actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

function ClassesPage(props) {
  const [edit, setEdit] = useState({isEditing: false, editRow: null})
  const dispatch = useDispatch();
  const userData = props.data.user?.userData ?? null
  const classesData = props.data.user?.classes ?? []
  let headings = ['period', 'name', 'students', 'addCode', 'edit', 'remove']

  if (userData && userData.role === 2) {
    headings = ['period', 'name', 'students', 'addCode']
  }
  const configureTable = {
    onClick: (group) => {
      props.history.push("/teacher/class/" + group._id);
    },
    tableHeadings: headings,
  }

  const RemoveComponent = (localProps) => {
    const { row } = localProps
    
    const removeClickHandler = (e, row) => {
      dispatch(removeClass({_id: row._id})).then(response => {
        dispatch(getClasses())
      })
      e.stopPropagation()
    }

    return (
      <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeClickHandler(e, row)} title="Are you sure you want to permanently delete this class?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type="danger">Remove</Button>             
      </Popconfirm>
    )
  }

  const EditComponent = (localProps) => {
    const { row, id } = localProps
    
    const editClickHandler = (e, row) => {
      if (edit.isEditing) {
        row.period = typeof row.period === 'object' ? row.period.props.defaultValue : row.period
        row.name = typeof row.name === 'object' ? row.name.props.defaultValue : row.name
        setEdit({isEditing: false, editRow: null})
        dispatch(saveEdit({name: row.name, period: parseInt(row.period), _id: row._id}))
      }

      else {
        row.period = <Input
                        className='period'
                        defaultValue={row.period}
                        onChange={e => row.period = e.target.value} 
                        onClick={e => e.stopPropagation()}
                      />
        row.name = <Input
                      className='name'
                      defaultValue={row.name}
                      onChange={e => row.name = e.target.value}
                      onClick={e => e.stopPropagation()}
                    />
        setEdit({isEditing: true, editRow: id})
      }
      e.stopPropagation()
    }

    return (
     <Button hidden={edit.isEditing && id !== edit.editRow}  onClick={(e) => editClickHandler(e, row)} type="primary">{edit.isEditing ? 'Save' : 'Edit'}</Button>
    )
  }

  const updatedClassesData = classesData.map((row, index) => {
    let newRow = {...row}
    newRow.remove = <RemoveComponent id={index} row={row} />
    newRow.edit = <EditComponent id={index} row={row} />
    newRow.students = row.members.length
    return newRow
  })

  useEffect(() => {
  	 dispatch(getClasses())
  }, [dispatch])
  
  return (
    <div className='body'>
      <div className='innerBody'>
        <Formik
          enableReinitialize
          initialValues={{
            period: '',
            name: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required('name is required'),
            period: Yup.number().positive(),
          })}

          onSubmit={(values, { setSubmitting }) => {
            let dataToSubmit = {
              period: parseInt(values.period),
              name: values.name,
              owner_id: userData._id
            };
            dispatch(postClass(dataToSubmit)).then((response) => {
              setSubmitting(false);
            })
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div hidden={userData && userData.role === 2}>
                <div className="form">
                  <h1>Add Class</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Period">
                          <Input
                            id="period"
                            type="text"
                            value={values.period}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.period && touched.period ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.period && touched.period && (
                            <div className="input-feedback">{errors.period}</div>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Name" validateStatus={errors.name && touched.name ? "error" : 'success'}>
                          <Input
                            id="name"
                            type="int"
                            value={values.name}
                            onChange={e =>{
                              values.sell = 0
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.name && touched.name ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.name && touched.name && (
                            <div className="input-feedback">{errors.name}</div>
                          )}
                          { values.name * values.price > values.ubucks ? <div className="input-feedback">Cost cannot be greater than UBucks</div> : null}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                      <Form.Item>
                        <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.period || errors.name}>
                          Submit
                        </Button>
                      </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>
        <h1>Classes</h1>
        <Table rowData={updatedClassesData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(ClassesPage))
// export default withRouter(UsersPage);