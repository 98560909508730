import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import { getTransactions } from "../../../_actions/user_actions";
import moment from 'moment'

function TransactionsPage(props) {
  const dispatch = useDispatch();
  const transactionsData = props.data.user?.teacherTrans ?? []
  const userId = props.data.user?.userData?._id ?? null
  const configureTable = {
    onClick: (group) => {

    },
    tableHeadings: [
      'student', 'date', 'description', 'value'
    ],
  }

  const updatedTransactionsData = transactionsData.map((row, index) => {
    let newRow = {...row}
    newRow.value = row.amount.toLocaleString('en', {useGrouping:true})
    newRow.date = moment(row.createdAt).format('YYYY-MM-DD HH:mm')
    return newRow
  })

  useEffect(() => {
    if (userId) {
      dispatch(getTransactions({_id: userId})) 
    } 
  }, [dispatch, userId])
  
  return (
    <div className='body'>
      <div className='innerBody'>
        <h1>Transactions</h1>
        <Table rowData={updatedTransactionsData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(TransactionsPage))