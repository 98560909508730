import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import { getUsers, postTransaction, getUserSpecial, getLottoLatest } from "../../../_actions/user_actions";
import { Button, Input, Popover, Form, message, Row, Col } from 'antd';
import './Users.css'

const FormItem = Form.Item;
const { Search } = Input;

function UsersPage(props) {
  const dispatch = useDispatch();
  let rowData = props.data.user?.usersData ?? []
  const userData = props.data.user?.userData ?? ["Loading Data"]

  const configureTable = {
    onClick: (user) => {
      props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'rank', 'firstName', 'lastName','netWorth'
    ],
  }

  const MakeForm = (localProps) => {
    const { visibleController, row } = localProps
    const [error, setError] = React.useState(true);
    const [form] = Form.useForm();
    
    const payClick = () => {
      dispatch(postTransaction(
        {
          amount: parseFloat(form.getFieldValue('pay')),
          description: form.getFieldValue('description'),
          owner_id: userData._id,
          student: row._id
        }
      )).then(response => {
        if (response.payload !== "") {
          message.error(response.payload, 10)
        }
        else {
          message.success(form.getFieldValue('pay') > 0 ? "User Paid" : "User Deducted")
        }
        form.resetFields()
      })
      visibleController()
    }

    const cancelClick = () => {
      visibleController()
      form.resetFields()
    }

    const onValuesChange = (values) => {
      const { pay, description } = form.getFieldsValue()
      let error1 = true
      let error2 = true
      if (pay === null || pay === undefined || pay === "" || isNaN(pay)) error1 = true
      else error1 = false
      if (description === null || description === undefined || description === "") error2 = true
      else error2 = false
      if (error1 || error2) setError(true)
      else setError(false)
    }
 
    return (
      <Form 
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{
        pay: null,
        description: null
        }}
        >
        <FormItem 
          rules={[
            {
              required: true,
              message: "An number must be entered",
              pattern: new RegExp(/^-?[0-9]\d*(\.\d+)?$/)
            },
          ]}
          name='pay'
        >
          <Input placeholder="Pay or Deduct" />
        </FormItem>
        <FormItem 
          rules={[
            {
              required: true,
              message: "A description must be entered",
            },
          ]}
          name='description'
        >
          <Input placeholder="Description" />
        </FormItem>
        <FormItem>
          <Button
            onClick={payClick} 
            type={form.getFieldValue('pay') < 0 ? "danger" : "primary"} 
            htmlType="submit" 
            disabled={error}
          >
            {form.getFieldValue('pay') < 0 ? "Deduct" : "Pay"}
          </Button>
          <Button 
            onClick={cancelClick}
            >Cancel</Button>
        </FormItem>
      </Form>
    )
  }

  const PayComponent = (localProps) => {
    const { row } = localProps
    const [pop, setPop] = React.useState(false);

    const popVisibleController = () => {
      setPop(!pop)
    }

    return (
      <div className='payContainer' onClick={e => e.stopPropagation()}>
        <Popover 
          content={<MakeForm visibleController={popVisibleController} row={row} />}
          trigger="click"
          placement="left"
          visible={pop}
        >
            <Button onClick={popVisibleController} type="primary">Pay/Deduct</Button>
        </Popover>
      </div>
    )
  }

  const SearchAndProfile = () => {
    const onSearch = (value) => {
      let dataToSubmit = {id : value}
      dispatch(getUserSpecial(dataToSubmit)).then(response => {
        if (!response.payload.netWorth) {
          message.error(response.payload)
        }
      })
    }

    const clickHandle = () => {
      props.history.push("/student/users/" + userData._id);
    }

    return (
      <div className='innerBody'>
        <Row>
          <Col span={8}>
            <Search
              placeholder="Enter students email"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={onSearch}
            />
          </Col>

          <Col span={8} offset={8}>
            <Button
              size="large"
              onClick={clickHandle}
              type="primary"
            >
              My Profile
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  rowData = rowData.filter((row, index) => {
    return row.netWorth !== undefined
  })

  configureTable.tableHeadings.push('pay')
  rowData = rowData.map((row, index) => {
    row.pay = <PayComponent id={index} row={row} />
    row.netWorth = row.netWorth.toLocaleString('en', {useGrouping:true})
    return row
  })

  useEffect(() => {
  	dispatch(getUsers())
    dispatch(getLottoLatest())
  }, [dispatch])

  useEffect(() => {
    if (props.data.user.hasOwnProperty("lottoLatest") && props.data.user.lottoLatest.hasOwnProperty("jackpot")) {

      message.info({
        type: 'info',
        content: "The raffle is growing: " + props.data.user.lottoLatest.jackpot,
        style: {
          marginTop: '8vh',
        }
      })

    }

    return () => {
      // Cleanup function to destroy the message on unmount
      message.destroy(); 
    };
    
  }, [props.data.user.lottoLatest, props.data.user])
  
  return (
    <div className='body'>
      <SearchAndProfile/>
      <br/>
      <div className='innerBody'>
  	   <Table rowData={rowData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(UsersPage))
