import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { getSettings, setSettings } from "../../../_actions/user_actions";
import { Row, Col, Form, message, Popover } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Formik } from 'formik';

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 30 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

function SettingsPageT(props) {
  const dispatch = useDispatch();
  const settings = props.data.user?.settings ?? {currencyLock: false}
  const userData = props.data.user?.userData ?? {lastName: "loading"}

  useEffect(() => {
  	 dispatch(getSettings())
  }, [dispatch])
  
  return (
    <Formik
      enableReinitialize
      initialValues={{
        currencyLock: settings.currencyLock,
      }}

    >
      {props => {
        const {
          values,
          handleSubmit,
        } = props;

        const handleSettingToggle = () => {
          values.currencyLock = !values.currencyLock
   		
					const dataToSubmit = {
						currencyLock: values.currencyLock
					}
			    dispatch(setSettings(dataToSubmit)).then(response => {

			    	if (response.payload) {
			    		message.error(response.payload.message)
			    	}
			    	else {
			    		dispatch(getSettings())
			    		message.success("settings updated")
			    	}
			    })
      	}	

        return (
      		<div className="body">
	            <div className='form'>
	              	<h1>Settings</h1>
	              	<Form {...formItemLayout} onSubmit={handleSubmit}>
		                <Row>
		                	<Popover content= {"Students cannot buy or sell " + userData.lastName + " bucks"}>
				            	  <QuestionCircleTwoTone />
				              </Popover>
		                	<Col>
                        <Form.Item label="Currency Lock">
                          <label className="switch">
                            <input id="changeCareer" type="checkbox" onChange={handleSettingToggle} checked={values.currencyLock} />
                            <span className="slider round"></span>
                          </label>
                        </Form.Item>
                      </Col>
		                </Row>
	               	</Form>
	            </div>
          	</div>
        );
      }}
    </Formik>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(SettingsPageT))