import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import './Table.css';

function Table(props) {
	const { rowData, tableData } = props
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: ''
  });
  
  const sortedData = useMemo(() => {
    let data = rowData ? [...rowData] : ['Loading Data'];
    if (sortConfig !== null) {
      data.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [rowData, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const returnName = (title) => {
    let heading = title.replace(/([A-Z])/g, ' $1')
    return heading.replace(/^\w/, (c) => c.toUpperCase())
  }

  const determineRowColor = (row) => {
    if (row && row.value) {
      return row.value.charAt(0) === "-" ? "redRow" : "greenRow"
    }
    return
  }

  function TableBody(localProps) {
	  const { rowData, tableData } = localProps
	  const rows = rowData.map((outerRow, outerIndex) => {
	    return (
	      <tr className={determineRowColor(outerRow)} onClick={() => tableData.onClick(outerRow)} key={outerIndex}>
          {
            tableData.tableHeadings.map((innerRow, innerIndex) => {
              return (
                <td key={innerIndex}>{outerRow[innerRow]}</td>
              ) 
            })
          }
	      </tr>
	    )
	  })

	  return <tbody>{rows}</tbody>
	}

  return (
  	<table>
        <thead>
          <tr>
            {
              tableData.tableHeadings.map((title, index) => {
                return (
                  <th key={index}>
                    <button type="button" onClick={() => requestSort(title)}
                      className={getClassNamesFor(title)}
                    >
                      {returnName(title)}
                    </button>
                  </th>
                ) 
              })
            }
          </tr>
        </thead>
        <TableBody rowData={sortedData} tableData={tableData}  />
    </table>
  );
}

export default withRouter(Table);