import React, { useState, useRef } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { registerUser } from "../../../_actions/user_actions";
import { useDispatch, connect } from "react-redux";
import { Form, Input, Row, Button, message, Switch } from 'antd';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
};



function RegisterPage(props) {
  const dispatch = useDispatch();

  const formRef = useRef();
  const [checked, setChecked] = useState({checked: true})
  const handleSwitch = e => {
    setChecked(e)
    formRef.current?.resetForm()
  }

  return (

    <Formik
      innerRef={formRef}
      initialValues={{
        email: '',
        lastName: '',
        firstName: '',
        password: '',
        confirmPassword: '',
        addCode: ''
      }}
      validationSchema={checked ? Yup.object().shape({
        firstName: Yup.string()
          .required('First name is required')
          .trim('First Name cannot include leading and trailing spaces')
          .max(15)
          .min(2),
        lastName: Yup.string()
          .required('Last Name is required')
          .trim('Last Name cannot include leading and trailing spaces')
          .max(15)
          .min(1),
        email: Yup.string()
          .min(4, "Must be at least 4 characters")
          .matches(/@/, "Must contain '@' symbol")
          .required('User Name is required'),
        addCode: Yup.string()
          .required('Add code is required')
          .max(11, 'Must be less than 11 characters')
          .min(9, 'Must be greater than 9 characters'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 characters')
          .required('Password is required'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required')
      }) :
    
        Yup.object().shape({
        firstName: Yup.string()
          .required('First name is required')
          .trim('First Name cannot include leading and trailing spaces')
          .max(15)
          .min(2),
        lastName: Yup.string()
          .required('Last Name is required')
          .trim('Last Name cannot include leading and trailing spaces')
          .max(15)
          .min(1),
        email: Yup.string()
          .email()
          .required('Email is required'),
        addCode: Yup.string()
          .required('Add code is required')
          .max(11, 'Must be less than 11 characters')
          .min(9, 'Must be greater than 9 characters'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 characters')
          .required('Password is required'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is required')
      })
    }

      onSubmit={(values, { setSubmitting }) => {
        let dataToSubmit = {
          email: values.email.toLowerCase(),
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          addCode: values.addCode
        };

        dispatch(registerUser(dataToSubmit)).then(response => {
          setSubmitting(false);
          if (response.payload.success) {
            message.success("Successfully registered, now login")
            props.history.push("/login");
          } 
          else {
            message.error(response.payload.message)
          }
        })   
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;

        return (
          <div className="app">
            <h2>Sign up</h2>
            <Switch 
              onChange={handleSwitch}
              checkedChildren="Student" 
              unCheckedChildren="Staff" 
              defaultChecked 
            />
            <br></br>
            <Form style={{ minWidth: '375px' }} {...formItemLayout} onSubmit={handleSubmit} >

              <Form.Item required label="First Name">
                <Input
                  id="firstName"
                  placeholder="Enter your first name"
                  type="text"
                  value={values.firstName}
                  onChange={e =>{
                    if (checked) {
                      values.email = ("@" + (values.lastName[0] ? values.lastName[0] : "") + (e.target.value ? e.target.value : "")).toLowerCase()
                      e.target.value = e.target.value.replace(/\s/g,'')
                    }
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  className={
                    errors.firstName && touched.firstName ? 'text-input error' : 'text-input'
                  }
                />
                {errors.firstName && touched.firstName && (
                  <div className="input-feedback">{errors.firstName}</div>
                )}
              </Form.Item>

              <Form.Item required label={checked ? "Last Initial" : "Last Name"}>
                <Input
                  id="lastName"
                  placeholder="Enter your last Name"
                  type="text"
                  value={values.lastName}
                  onChange={e =>{
                    if (checked) {
                      e.target.value = e.target.value[0]
                      values.email = ("@" + (e.target.value[0] ? e.target.value[0] : "") + (values.firstName ? values.firstName : "")).toLowerCase()
                      e.target.value = e.target.value.replace(/\s/g,'')
                    }
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  className={
                    errors.lastName && touched.lastName ? 'text-input error' : 'text-input'
                  }
                />
                {errors.lastName && touched.lastName && (
                  <div className="input-feedback">{errors.lastName}</div>
                )}
              </Form.Item>

              <Form.Item required label={checked ? "UserName" : "Email"} hasFeedback validateStatus={errors.email && touched.email ? "error" : 'success'}>
                <Input
                  id="email"
                  readOnly={checked}
                  placeholder={checked ? "User Name with @" : "Email"}
                  type="email"
                  value={values.email}
                  onChange={e => {
                    e.target.value = (e.target.value.replace(/\s/g,'')).toLowerCase()
                    handleChange(e)
                  }}
                  onBlur={e => {
                    e.target.value = (e.target.value.replace(/\s/g,'')).toLowerCase()
                    handleBlur(e)
                  }}
                  className={
                    errors.email && touched.email ? 'text-input error' : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </Form.Item>

              <Form.Item required label="Add Code">
                <Input
                  id="addCode"
                  placeholder="Enter your last Name"
                  type="text"
                  value={values.addCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.addCode && touched.addCode ? 'text-input error' : 'text-input'
                  }
                />
                {errors.lastName && touched.lastName && (
                  <div className="input-feedback">{errors.addCode}</div>
                )}
              </Form.Item>

              <Form.Item required label="Password" hasFeedback validateStatus={errors.password && touched.password ? "error" : 'success'}>
                <Input
                  id="password"
                  placeholder="Enter your password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password ? 'text-input error' : 'text-input'
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </Form.Item>

              <Form.Item required label="Confirm" hasFeedback validateStatus={errors.confirmPassword && touched.confirmPassword ? "error" : 'success'}>
                <Input
                  id="confirmPassword"
                  placeholder="Enter your confirmed Password"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.confirmPassword && touched.confirmPassword ? 'text-input error' : 'text-input'
                  }
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="input-feedback">{errors.confirmPassword}</div>
                )}
              </Form.Item>
              <Row type="flex" justify="center" align="middle">
                <Form.Item>
                  <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.confirmPassword || errors.firstName || errors.lastName || errors.password || errors.addCode || errors.email }>
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(RegisterPage)
