/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { auth } from '../_actions/user_actions';
import { useSelector, useDispatch } from "react-redux";

export default function authRedirect(SpecificComponent, option) {
    function AuthenticationCheck(props) {

        let user = useSelector(state => state.user);
        const dispatch = useDispatch();

        useEffect(() => {
            //To know my current status, send Auth request 
            dispatch(auth()).then(response => {
                //Not Loggined in Status 
                if (!response.payload.isAuth) {
                    if (option) {
                        props.history.push('/login')
                    }
                    //Loggined in Status 
                } else {
                    //Logged in Status, but Try to go into log in page 
                    if (option === false) {
                        props.history.push('/')
                    }
                    else if (response.payload.role === 2) {
                        props.history.push('/prin/approved/' + response.payload._id)
                    }
                    else if (response.payload.role === 1) {
                        props.history.push('/teacher/classes')
                    }
                    else {
                        props.history.push('/student/users')
                    }   
                }
            })

        }, [])

        return (
            <SpecificComponent {...props} user={user} />
        )
    }
    return AuthenticationCheck
}


