import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import { getEvents } from "../../../_actions/user_actions";

function EventsPage(props) {
  const dispatch = useDispatch();
  const eventsData = props.data.user?.teacherEvents ?? []

  const configureTable = {
    onClick: (group) => {

    },
    tableHeadings: [
      'student', 'description', 'value'
    ],
  }

  const updatedEventsData = eventsData.map((row, index) => {
    let newRow = {...row}
    newRow.value = row.value.toLocaleString('en', {useGrouping:true})
    newRow.student = row.firstName + " " + row.lastName
    return newRow
  })

  useEffect(() => {
  	 dispatch(getEvents())
  }, [dispatch])
  
  return (
    <div className='body'>
      <div className='innerBody'>
        <h1>Events</h1>
        <Table rowData={updatedEventsData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(EventsPage))