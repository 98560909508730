import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { marketItemBuy, getMarketItems, getSchoolTeachers, getTeacherBuck } from "../../../_actions/user_actions";
import { Input, Select, message, Card, Form, Button, List } from 'antd';

function StudentStorePage(props) {
  const dispatch = useDispatch();
  const marketItems = props.data.user?.marketItems ?? [{owner_id: {_id: "Loading"}, title: "Loading", _id: "Loading", count: 0, cost: 0, buyers: []}]
  const teachers = props.data.user?.teachers ?? ["Loading"]
  const teacherBuck = props.data.user?.teacherBuck ?? 0
  const [random, setRandom] = useState("")

  useEffect(() => {
		dispatch(getSchoolTeachers()).then(response => {
			setRandom(response.payload[Math.floor(Math.random() * response.payload.length)]._id)
		})
  }, [dispatch])

  useEffect(() => {
  	if (random !== "") {
			dispatch(getMarketItems({_id: random}))
			dispatch(getTeacherBuck({_id: random}))
		}
  }, [dispatch, random])

  return (
  	<div className="flexContainerColumn">
  		<Selector
      	id='teacherMarket'
      	value={random}
      	optionData = {teachers}
      	setRandom = {setRandom}
      />
      <Input
	      readOnly
	      id="bucks"
	      value={teacherBuck + " Bucks"}
	      style={{ width: 150 }}
	     />
			<div className="flexContainerWrap">
		     {
		        marketItems.map((item, index) => {
		          return (
		            <CustomCard key={index} teacherBuck={teacherBuck} teacherId={item.owner_id._id} dispatch={dispatch} _id={item._id} buy={item.count > 0} title={item.title} count={item.count} cost={item.cost} buyers={item.buyers} />
		          ) 
		        })
		      }
		  </div>
		 </div>
  );
}


function CustomCard(props) {

  return (
  	<Card title={props.title}>
  		<div>Cost: {props.cost}</div>
  		<div>Count: {props.count}</div>
  		<CustomForm teacherId={props.teacherId} teacherBuck={props.teacherBuck} title={props.title} cost={props.cost} count={props.count} dispatch={props.dispatch} _id={props._id} buyers={props.buyers} buy={props.buy} onTitleChange={props.onTitleChange} onNumberChange={props.onNumberChange} onCostChange={props.onCostChange} />
    </Card> 
  )

}

function CustomForm(props) {
	const layout = {
	  labelCol: { span: 8 },
	  wrapperCol: { span: 16 },
	};

	const tailLayout = {
	  wrapperCol: { offset: 8, span: 16 },
	};

	const [form] = Form.useForm();

  const onFinish = (values) => {
    props.dispatch(marketItemBuy(
      {
        _id: props._id,
        user_id: props.user_id,
        teacher_id: props.teacherId,
      }
    )).then(response => {
      if (response.payload !== null) {
        message.error(response.payload, 10)
      }
      else {
      	props.dispatch(getMarketItems({_id: props.teacherId}))
      	props.dispatch(getTeacherBuck({_id: props.teacherId}))
        message.success("Item Purchased")
      }
    })
  };

  return (
    <Form
    	{...layout}
    	form={form}
    	name="control-hooks"
    	onFinish={onFinish}
    >
    	{detailsOrBuyers(props)}
      <Form.Item {...tailLayout}>
        <Button hidden={props.teacherBuck < props.cost || !props.buy} type="primary" htmlType="submit">
		      Buy
		    </Button>
      </Form.Item>
    </Form>
  );
}

const detailsOrBuyers = (props) => {
	
	return (
		<Form.Item>
    	<h3>Buyers:</h3>
    		<div className="flexContainerNoWrap">
	        <List
			      bordered
			      dataSource={props.buyers}
			      renderItem={(item) => (
			        <List.Item>
			        	<div className="leftContainer">
			          	{item.firstName + " " + item.lastName}
			          </div>
			        </List.Item>
			      )}
			    />
			  </div>
    </Form.Item>
	)
}

function Selector(localProps) {
	const { optionData, value, setRandom } = localProps
	const options = optionData.map((option, index) => {
		return {value: option._id, label: option.name}
	})

	const handleChange = (id) => {
	  setRandom(id)
	}

	return (
		<Select value={value} style={{ width: 150 }} onChange={handleChange} options={options} />
	)
}


function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(StudentStorePage))