import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import './Money.css'
import Table from '../Table/Table'
import { setAccount, getStudentAccounts, getBuckTransactions } from "../../../_actions/user_actions";
import moment from 'moment'

function SavingsPage(props) {
	const dispatch = useDispatch();
  const accounts = props.data.user?.accounts ?? []
  const buckTransactions = props.data.user?.buckTransactions ?? []

  useEffect(() => {
    dispatch(getStudentAccounts())
    dispatch(getBuckTransactions())
  }, [dispatch])
  
	const configTableSavings = {
    onClick: (buck) => {
      let chosen = accounts.find(account => buck.buck === account.buck.name && parseFloat(buck.balance.replace(new RegExp(",", "g"), '')).toFixed(3) === account.balance.toFixed(3))
      const UBuck = accounts.find(account => "UBuck" === account.buck.name && 1 === account.conversion)
      dispatch(setAccount([chosen, UBuck])) //how this returns needs to sync up with how accounts send the info from exchange
      const path = props.location.pathname
      let n = path.lastIndexOf('/');
      let short_id = path.substring(n + 1);
      props.history.push("/student/savings/convert/" + short_id);
    },
    tableHeadings: [
      'buck', 'balance', 'conversion'
    ],
  }

  const configTableTransactions = {
    onClick: (user) => {
      // props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'buck', 'date', 'description', 'value', 'conversion', 'balance'
    ],
  }

  let tableRowData = accounts.map((account, index) => {
    let tempAccount = {}
    tempAccount.buck = account.buck.name
    tempAccount.balance = account.balance.toLocaleString('en', {useGrouping:true})
    tempAccount.conversion = account.conversion.toLocaleString('en', {useGrouping:true})
    return tempAccount
  })

  let transactionData = buckTransactions.map((transaction, index) => {
    let tempTransaction = {}
    tempTransaction.buck = transaction.name
    tempTransaction.date = moment(transaction.createdAt).format('YYYY-MM-DD HH:mm')
    tempTransaction.description = transaction.description
    tempTransaction.value = transaction.amount.toLocaleString('en', {useGrouping:true})
    tempTransaction.balance = transaction.balance.toLocaleString('en', {useGrouping:true})
    tempTransaction.conversion = transaction.conversion
    return tempTransaction
  })

  return (
    <div className ='body'>
      <div className = 'innerBody'>
        <div className='tableData'>
          <h1>Savings Accounts</h1>
        	<Table rowData={tableRowData} tableData={configTableSavings} />
          <h1>Transactions</h1>
          <Table rowData={transactionData} tableData={configTableTransactions} />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(SavingsPage)