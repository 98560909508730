import React, { useEffect } from "react";
import { connect } from "react-redux";
import {  } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Button, Row, Col } from 'antd';
import Table from '../Table/Table'
import './Money.css'
import mockData from '../User/mockData.json' //this is mock data delete it later

const formItemLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 18 },
  },
};

function RealEstatePage(props) {

  useEffect(() => {
    
  }, [])

  const configTablePositions = {
    onClick: (house) => {
      console.log('dispatch a search for ' + house.address)
    },
    tableHeadings: [
      'address', '% of account', 'purchase Price'
    ],
  }

  const configTableTransactions = {
    onClick: (user) => {
      // props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'date', 'address', 'value'
    ],
  }

  const handleSearch = address => {
    console.log('searched for ' + address)
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            address: '1128 Buster Way Livermore CA 94551',//use dynamic values
            ubucks: 1000000,
            cost: 10000,
            owned: false,
            loan: false,
          }}
          validationSchema={Yup.object().shape({
            address: Yup.string()
              .required('address is required'),
            ubucks: Yup.number().min(0),
          })}

          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
            	console.log('making transaction, server will determine buy or sell')
              setSubmitting(false);
            }, 500);
          }}

          onChange={(values) => {
          	console.log('something changed')
          	console.log(values)
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div>
                <div className="form">
                  <h1>Property to {values.owned ? 'Sell' : 'Buy'}</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Row>
                      <Col span={20}>
                        <Form.Item label="Address">
                          <Input
                            id="address"
                            placeholder="Enter the house address"
                            type="text"
                            value={values.address}
                            onChange={e => {
                              values.cost = 0
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.address && touched.address ? 'text-input error' : 'text-input'
                            }
                          />
                          {values.cost === 0 ? <Col><p className="input-feedback">You need to hit search</p></Col> : null}
                          {errors.address && touched.address && (
                            <div className="input-feedback">{errors.address}</div>
                          )}
                        </Form.Item>
                      </Col>
                      <Button type="primary" onClick={() => handleSearch(values.address)}>Search</Button>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <Form.Item label="Cost">
                        <Input
                          readOnly
                          id="cost"
                          type="int"
                          value={values.cost}
                        />
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item label="UBucks">
                        <Input
                          readOnly
                          id="ubucks"
                          type="int"
                          value={values.ubucks}
                        />
                        { values.ubucks < values.cost && !values.owned ? <div className="input-feedback">You can't afford this</div> : null}
                        </Form.Item>
                      </Col>
                      <Col span={20}>
                        <Form.Item label="Loan" hidden={true}>
                          <Input
                            readOnly
                            id="loan"
                            type="int"
                            value={values.loan}
                           />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                      <Form.Item hidden={(values.ubucks < values.cost && !values.owned) || values.cost === 0}>
                        <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.address}>
                          {values.owned ? 'Sell' : 'Buy'}
                        </Button>
                      </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>
        <div className="tableData">
          <h1>Portfolio</h1>
          <Table rowData={mockData.realData} tableData={configTablePositions} />
          <h1>Transactions</h1>
          <Table rowData={mockData.realTransactions} tableData={configTableTransactions} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(RealEstatePage)
