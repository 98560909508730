import React from "react";
import { useDispatch, connect } from "react-redux";
import { Select, Button, Row, Col, Form, Input, message } from 'antd';
import { postConversion, getExchangeRate } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import './Money.css'

const exchangeCharge = .01

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 8,
      offset: 10,
    },
    sm: {
      span: 16,
      offset: 11,
    },
  },
};

function ConvertPage(props) {
	const dispatch = useDispatch();
  const userData = props.data.user.userData
  
  let accounts = props.data.user?.bucks ?? ["Loading"]
  let chosenAccount = props.data.user.chosenAccount === undefined ?  accounts[0] : props.data.user.chosenAccount[0]
  let buyAccount = props.data.user.chosenAccount === undefined ? accounts[0] : props.data.user.chosenAccount[1]
  if (buyAccount === undefined) buyAccount = chosenAccount

  if (typeof(chosenAccount) === "object") {
    chosenAccount.name = chosenAccount.buck.name
    buyAccount.name = buyAccount.buck.name
  }

  const { Option } = Select;

	function handleSelectChange(e, values, id) {
		values.buy = 0
		values.sell = 0
		values.charge = 0
    exchangeCheck(e, values, id)
	  return {
		  target: {
		    type: "change",
		    id: id,
		    value: e
		  }
		}
	}

  function exchangeCheck(e, values, id){
    let dataToSubmit = {
      sellCurrency: values.sellCurrency,
      buyCurrency: values.buyCurrency
    }
    dataToSubmit[id] = e
    if (dataToSubmit.buyCurrency.includes("Buck")) dataToSubmit.buyCurrency = buyAccount._id 
    if (dataToSubmit.sellCurrency.includes("Buck")) dataToSubmit.sellCurrency = chosenAccount._id
    if (dataToSubmit.buyCurrency === "Debt") dataToSubmit.buyCurrency = buyAccount._id 
    if (dataToSubmit.sellCurrency === "Debt") dataToSubmit.sellCurrency = chosenAccount._id

    dispatch(getExchangeRate(dataToSubmit))
  }


	function Selector(localProps) {
		const { optionData, id, value, onChange } = localProps //use props.data.user.chosenAccount.bucks for default value
		const options = optionData.map((option, index) => {
			return <Option key={option._id}>{option.name}</Option>
		})
		return (
			<Select id={id} value={value} onChange={onChange}>
				{options}
			</Select>
		)
	}


	return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            sellCurrency: chosenAccount.name,
            conversion: Math.abs(chosenAccount.conversion),
            buyCurrency: buyAccount.name,
            owned: chosenAccount.balance,
            sell: 0,
            buy: 0,
            charge: 0
          }}

          onSubmit={(values, { setSubmitting }) => {
            let dataToSubmit = {
              accountTo: values.buyCurrency,
              amount: parseFloat(values.sell),
              accountFrom: values.sellCurrency
            }
            if (dataToSubmit.accountTo.includes("Buck")) dataToSubmit.accountTo = buyAccount._id 
            if (dataToSubmit.accountFrom.includes("Buck")) dataToSubmit.accountFrom = chosenAccount._id
            if (dataToSubmit.accountTo === "Debt") dataToSubmit.accountTo = buyAccount._id
            dispatch(postConversion(dataToSubmit)).then(response => {
                if (response.payload) {
                message.error(response.payload)
              }
              else {
                message.success("Conversion Successful")
                props.history.push("/student/savings/" + userData._id)
              }
              setSubmitting(false);
            })
          }}
        >

          {props => {
            const {
              values,
              handleChange,
              isSubmitting,
              handleSubmit
            } = props;

            return (
              <div className='currencyForm'>
                <div className="form">
                  <h1>Convert School Currencies</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Row>
                      <Col span={8}>
                        <Form.Item label="From">
                          <Selector
                          	id='sellCurrency'
                          	value={values.sellCurrency}
                          	onChange={e => {
                          		handleChange(handleSelectChange(e, values, 'sellCurrency'))
                          	}}
                          	optionData = {accounts} 
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Conversion">
                        <Input
                          readOnly
                          id="conversion"
                          type="int"
                          value={values.conversion}
                        />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="To">
                          <Selector
                          	id='buyCurrency'
                          	value={values.buyCurrency}
                          	onChange={e => {
                          		handleChange(handleSelectChange(e, values, 'buyCurrency'))
                          	}}
                          	optionData = {accounts} 
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Owned">
                          <Input
                            readOnly
                            id="owned"
                            type="int"
                            value={values.owned}
                           />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Outstanding Debt" hidden = {!(values.buyCurrency === "Debt" || values.buyCurrency === "debt")}>
                          <Input
                            id="debt"
                            type="double"
                            readOnly
                            value={buyAccount.balance}
                          />
                        </Form.Item >
                      </Col>
                    </Row>
                    <Row>
                    	<Col span={12}>
                        <Form.Item label="Sell">
                          <Input
                            id="sell"
                            type="int"
                            onChange={e =>{
                            	values.buy = ((e.target.value*.99) * values.conversion).toFixed(2)
                            	values.charge = (e.target.value * exchangeCharge).toFixed(2)
                            	handleChange(e)
                            }}
                            value={values.sell}
                          />
                          {parseFloat(values.sell) > values.owned ? <p className="input-feedback">You can't sell more than you own</p> : null}
                        	{values.sell <= 0 || values.buy <= 0 ? <span className="input-feedback">You must buy or sell something</span> : null}
                        	{isNaN(values.charge) ? <span className="input-feedback">You can only use numbers here</span> : null}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Buy">
                          <Input
                            id="buy"
                            type="int"
                            onChange={e =>{
                              values.sell = ((e.target.value / values.conversion) * 1.01).toFixed(3)
                              values.charge = ((e.target.value / values.conversion) * 1.01 * exchangeCharge).toFixed(3)
                              handleChange(e)
                            }}
                            value={values.buy}
                           />
                        </Form.Item>
                        
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Charge">
                          <Input
                            readOnly
                            id="charge"
                            type="int"
                            value={values.charge}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item hidden = {parseFloat(values.sell) > values.owned} {...tailFormItemLayout}>
                      <Button hidden = { isSubmitting || values.sell <= 0 || values.buy <= 0 || isNaN(values.charge) || values.sellCurrency === values.buyCurrency || values.sellCurrency === "Debt" || (values.buyCurrency === "Debt" && buyAccount.balance === 0)} onClick={handleSubmit} type="primary">
                        Submit
                      </Button>
                    </Form.Item>                    
                  </Form>
                </div>
                
              </div>
            );
          }}	
        </Formik>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default (connect(mapStateToProps)(ConvertPage))