export const LOGIN_USER = 'login_user';
export const REGISTER_USER = 'register_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const GET_USERS = 'get_users';
export const GET_USER = 'get_user';
export const GET_USER_SPECIAL = 'get_user_special';
export const UPDATE_USER = 'update_user';
export const SET_ACCOUNT = 'set_account';
export const GET_CLASSES = 'get_classes';
export const EDIT_CLASSES = 'edit_classes';
export const GET_CLASS = 'get_class';
export const UPDATE_CLASS = 'update_class';
export const KICK_MEMBER = 'kick_member';
export const MAKE_AUCTION = 'make_auction';
export const GET_AUCTIONS = 'get_auctions';
export const REMOVE_ADMIN = 'remove_admin';
export const ADD_ADMIN = 'add_admin';
export const TOAST = 'toast';
export const GET_BUCK = 'get_buck';
export const GET_ACCOUNTS = 'get_accounts';
export const GET_BUCK_TRANSACTIONS = 'get_buck_transactions';
export const GET_TEACHERS = 'get_teachers';
export const UPDATE_TCLASS = 'update_tclass';
export const KICK_TEACHER = 'kick_teacher';
export const SEARCH_CRYPTO = 'search_crypto';
export const CRYPTO_ACCOUNTS = 'crypto_accounts';
export const CRYPTO_TRANS = 'crypto_trans';
export const CRYPTO_HISTORY = 'crypto_history';
export const BUCKS = 'bucks';
export const TEACHER_TRANSACTIONS = 'teacher_transactions';
export const TEACHER_EVENTS = 'teacher_events';
export const SETTINGS = 'settings';
export const GET_MARKET_ITEMS = 'get_market_items'
export const GET_SCHOOL_TEACHERS = 'get_school_teachers'
export const TEACHER_BUCK = 'teacher_buck'
export const STUDENT_COUNT = 'student_count'
export const GET_LOTTO_LATEST = 'get_lotto_latest'
export const GET_LOTTO_PREVIOUS = 'get_lotto_previous'
export const SYMBOL_LOOKUP = 'symbol_lookup'
export const CD_ACCOUNTS = 'cd_accounts'
export const CD_TRANSACTIONS = 'cd_transactions'
export const CRYPTOS = 'cryptos'
export const PURCHASES = 'purchases'
export const TAX_BRACKETS = 'tax_brackets'
