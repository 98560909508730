import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { makeMarketItem, deleteMarketItem, refundMarketItem, resolveMarketItem, getMarketItems } from "../../../_actions/user_actions";
import { message, Card, Form, Input, InputNumber, Button, List, Popconfirm } from 'antd';

function TeacherStorePage(props) {
  const dispatch = useDispatch();
  const teacherId = props.data.user?.userData?._id ?? null
  const marketItems = props.data.user?.marketItems ?? []
  const [title, setTitle] = useState("Title")
  const [count, setCount] = useState(1)
  const [cost, setCost] = useState(1)

  useEffect(() => {
  	if (teacherId) {
  		dispatch(getMarketItems({_id: teacherId}))
		let intervalId = setInterval(() => {
			dispatch(getMarketItems({_id: teacherId}))
		}, 30000);

		const timeoutId = setTimeout(() => {
			clearInterval(intervalId);
		  }, 600000);
	  
		  // Cleanup interval and timeout on component unmount
		  return () => {
			clearInterval(intervalId);
			clearTimeout(timeoutId);
		  };
  	}
  }, [dispatch, teacherId])

  const onNumberChange = (value) => {
	  setCount(value)
	};

	const onTitleChange = (value) => {
	  setTitle(value.target.value)
	};

	const onCostChange = (value) => {
	  setCost(value)
	};

	//line 42 should always run but line 43 should run for as many market items I get.

  return (
		<div className="flexContainerWrap">
       <CustomCard key="-1" teacherId={teacherId} dispatch={dispatch} addition={true} onTitleChange={onTitleChange} onNumberChange={onNumberChange} onCostChange={onCostChange}  title={title} count={count} cost={cost} />
       {
          marketItems.map((item, index) => {
            return (
              <CustomCard key={index} teacherId={teacherId} dispatch={dispatch} _id={item._id} addition={false} title={item.title} count={item.count} cost={item.cost} buyers={item.buyers} />
            ) 
          })
        }
    </div>
  );
}


function CustomCard(props) {

  return (
  	<Card title={props.title}>
  		<div>Cost: {props.cost}</div>
  		<div>Count: {props.count}</div>
  		<CustomForm teacherId={props.teacherId} title={props.title} cost={props.cost} count={props.count} dispatch={props.dispatch} _id={props._id} buyers={props.buyers} addition={props.addition} onTitleChange={props.onTitleChange} onNumberChange={props.onNumberChange} onCostChange={props.onCostChange} />
    </Card> 
  )

}

function CustomForm(props) {
	const layout = {
	  labelCol: { span: 8 },
	  wrapperCol: { span: 16 },
	};

	const tailLayout = {
	  wrapperCol: { offset: 8, span: 16 },
	};

	const [form] = Form.useForm();

  const onFinish = (values) => {
    props.dispatch(makeMarketItem(
      {
        count: props.count,
        title: props.title,
        cost: props.cost,
      }
    )).then(response => {
      if (response.payload !== null) {
        message.error(response.payload, 10)
      }
      else {
      	props.dispatch(getMarketItems({_id: props.teacherId}))
        message.success("Item Created")
      }
    })
  };

  return (
    <Form
    	{...layout}
    	form={form}
    	name="control-hooks"
    	onFinish={onFinish}
    >
    	{detailsOrBuyers(props)}
      <Form.Item {...tailLayout}>
        {submitOrRemove(props)}
      </Form.Item>
    </Form>
  );
}

const detailsOrBuyers = (props) => {

	const refundOnClick = (_id) => {
  	let dataToSubmit = {_id: props._id, user_id: _id}
    props.dispatch(refundMarketItem(dataToSubmit)).then(response => {
      if (response.payload !== null) {
        message.error(response.payload, 10)
      }
      else {
      	props.dispatch(getMarketItems({_id: props.teacherId}))
        message.success("Item Refunded")
      }
    })
  }

  const resolveOnClick = (_id) => {
  	let dataToSubmit = {_id: props._id, user_id: _id}
    props.dispatch(resolveMarketItem(dataToSubmit)).then(response => {
      if (response.payload !== null) {
        message.error(response.payload, 10)
      }
      else {
      	props.dispatch(getMarketItems({_id: props.teacherId}))
        message.success("Item Resolved")
      }
    }) 
  }

	if (props.addition) {
		return (
			<div>
    		<Form.Item name="title" label="Title" rules={[{ required: true }]}>
	        <Input onChange={props.onTitleChange} />
	      </Form.Item>
	      <Form.Item name="cost" label="Cost" rules={[{ required: true }]}>
	       	<InputNumber min={1} max={1000000} onChange={props.onCostChange} />
	      </Form.Item>
	      <Form.Item name="count" label="Count" rules={[{ required: true }]}>
	       	<InputNumber min={1} max={1000} onChange={props.onNumberChange} />
	      </Form.Item>
	    </div>
		)
	}
	
	return (
		<Form.Item>
    	<h3>Buyers:</h3>
    		<div className="flexContainerNoWrap">
	        <List
			      bordered
			      dataSource={props.buyers}
			      renderItem={(item) => (
			        <List.Item className="flexContainerNoWrap">
			        	<div className="leftContainer">
			          	{item.firstName + " " + item.lastName}
			          </div>
			          <div className="rightContainer">
				          <div className="spread">
					          <Button type="primary" onClick={()=>{resolveOnClick(item._id)}}>
						          Resolve
						        </Button>
						      </div>
						      <div className="spread">
						        <Button type="danger" onClick={()=>{refundOnClick(item._id)}}>
						          Refund
						        </Button>
						      </div>
						    </div>
			        </List.Item>
			      )}
			    />
			  </div>
    </Form.Item>
	)
}

const submitOrRemove = (props) => {

	const removeOnClick = (_id) => {
  	let dataToSubmit = {_id: _id}
    props.dispatch(deleteMarketItem(dataToSubmit)).then(response => {
      if (response.payload !== null) {
        message.error(response.payload, 10)
      }
      else {
      	props.dispatch(getMarketItems({_id: props.teacherId}))
        message.success("Item Removed")
      }
    })
  }

	if (props.addition) {
		return (
			<Button type="primary" htmlType="submit">
        Submit
      </Button>
		)
	}

	return (
		<Popconfirm onCancel={e => e.stopPropagation()} onConfirm={() => {removeOnClick(props._id)}} title="This will refund all unresolved purchases" okText="Confirm" cancelText="Cancel">
      <Button onClick={(e) => e.stopPropagation()} type="danger">Delete</Button>
    </Popconfirm>
	)
}


function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(TeacherStorePage))