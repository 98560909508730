import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Formik } from 'formik';
import { getClassMembers, addClass, removeAdmin } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, Popconfirm, message } from 'antd';
import Table from '../Table/Table'
import './User.css';

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 8
  },
};
const tailLayout = {
  wrapperCol: {
    span: 16,
  },
};

function StudentStorePage(props) {
  const dispatch = useDispatch();
  const userData = props.data.user.userData
  const classesData = props.data.user?.classes ?? []

  useEffect(() => {
  	 dispatch(getClassMembers())
  }, [dispatch])

  const configTableClasses = {
    onClick: (group) => {
      // console.log('leave the class?' + group.teacher)
    },
    tableHeadings: [
      'period', 'teacher'
    ],
  }

  const RemoveComponent = (localProps) => {
    const { row } = localProps
    
    const removeClickHandler = (row) => {
      let dataToSubmit = {_id: row._id, kick_id: userData._id}
      dispatch(removeAdmin(dataToSubmit))
    }

    return (
      <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeClickHandler(row)} title="Are you sure you want to leave this class?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type="danger">Remove</Button>             
      </Popconfirm>
    )
  }

  const updatedClassesData = classesData.map((row, index) => {
    let newRow = {...row}
    newRow.remove = <RemoveComponent id={index} row={row} />
    newRow.teacher = row.owner_id.lastName
    return newRow
  })

  return (
  	<div className="body">
      <div className="innerBody">
		    <Formik
		      enableReinitialize
		      initialValues={{
		        addCode: '',
		      }}

		      onSubmit={(values, { setSubmitting }) => {
	          const path = props.location.pathname
	          const n = path.lastIndexOf('/');
	          const short_id = path.substring(n + 1);

	          let dataToSubmit = {
	            addCode: values.addCode,
	            _id: short_id
	          };

	          dispatch(addClass(dataToSubmit)).then((response) => {
	          	values.addCode = ""
	          	setSubmitting(false);
	          	if (response.payload.message) {
				        message.error(response.payload.message)
				      } 
	          })
		      }}
		    >

		      {props => {
		        const {
		          values,
		          isSubmitting,
		          handleChange,
		          handleSubmit
		        } = props;

		        return (
		          <div>
		            <div className='form'>
		              <h1>Adding a Class</h1>
		              <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Form.Item label="Class Code">
                      <Input
                        id="addCode"
                        type="text"
                        value={values.addCode}
                        onChange={handleChange}
                      />
                  	</Form.Item>
                  	<Row type="flex" justify="center" align="middle">
	                  	<Form.Item {...tailLayout}>
	                  		<Button onClick={handleSubmit} type="primary" disabled={isSubmitting || values.addCode === ""}>
			                    Submit
			                  </Button>
			                </Form.Item>
			               </Row>
		              </Form>
		            </div>
		          </div>
		        );
		      }}
		    </Formik>

		    <div className="tableData">
		      <h1>Accepted Classes</h1>
		      <Table rowData={updatedClassesData} tableData={configTableClasses} />
		    </div>
		  </div>
		</div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(StudentStorePage))
