import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getStudentUBucks, CDTransaction, getCDPortfolio, getCDTransactions, CDRefund } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, Col, Select, Popover, Popconfirm } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import MyGraph from '../Utilities/MyGraph'
import Table from '../Table/Table'
import './Money.css'
import moment from 'moment'

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

function CdsPage(props) {
  const dispatch = useDispatch();
  const uBucks = props.data.user.buck?.value ?? 0
  const CDAccounts = props.data.user?.CDAccounts ?? []
  const CDTransactions = props.data.user?.CDTransactions ?? []

  useEffect(() => {
    dispatch(getStudentUBucks())
    dispatch(getCDPortfolio())
    dispatch(getCDTransactions())
  }, [dispatch])

  const configTablePositions = {
    onClick: (crypto) => {
      console.log('dispatch a search for ' + crypto.name)
    },
    tableHeadings: [
      'maturityDate', 'principal', 'currentValue', 'refundValue', 'interest', 'action'
    ],
  }

  const generateGraphData = (days = 14, interest = 1.03, principal = 100) => {
    return Array.from({ length: days }, (_, index) => [index + 1, principal * (interest ** (index + 1))]);
  }

  const makeGraphConfig = (values) => {
    return {
      title: {
        text: `Investment Growth`
      },
      labels: {
        enabled: true
      },
      chart: {
        height: 35 + '%',
        width: 1050
      },
      legend: {
        enabled: false
      },
      series: [{
        name: 'UBucks',
        data: generateGraphData(values.time, timeToInterest(values.time), values.prinInv)
      }]
    }
  }

  const RefundComponent = (localProps) => {
    const { row } = localProps
    
    const refundClickHandler = (e, row) => {
      dispatch(CDRefund({_id: row.Ts})).then(response => {
        dispatch(getStudentUBucks())
        dispatch(getCDPortfolio())
        dispatch(getCDTransactions())
      })
      e.stopPropagation()
    }

    if (moment(row.maturity).isBefore(moment())) {
      return (
        <Button onClick={(e) => refundClickHandler(e, row)} type="primary">Refund</Button>
      )
    }
    else {
      return (
        <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => refundClickHandler(e, row)} title="Are you sure you want to refund early and lose money?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type="danger">Refund</Button>             
        </Popconfirm>
      )
    }  
  }

  let accounts = CDAccounts.map((account, index) => {
    let tempAccount = {}
    tempAccount.maturityDate = moment(account.maturity).format('YYYY-MM-DD HH:mm')
    tempAccount.principal = account.principal.toLocaleString('en', {useGrouping:true})
    tempAccount.currentValue = account.currentValue.toFixed(2).toLocaleString('en', {useGrouping:true})
    tempAccount.refundValue = account.refundValue.toFixed(2).toLocaleString('en', {useGrouping:true})
    tempAccount.interest = Math.floor((account.interest - 1) * 100) + "%"
    tempAccount.action = <RefundComponent id={index} row={account} />
    return tempAccount
  })

  const configTableTransactions = {
    onClick: (user) => {
      // no action
    },
    tableHeadings: [
      'date', 'value', "description"
    ],
  }

  let transactionData = CDTransactions.map((transaction, index) => {
    let tempTransaction = {}
    tempTransaction.date = moment(transaction.createdAt).format('YYYY-MM-DD HH:mm')
    tempTransaction.description = transaction.description
    tempTransaction.value = transaction.amount.toLocaleString('en', {useGrouping:true})
    return tempTransaction
  })

  function handleSelectChange(e, values, id) {
    let interest = timeToInterest(e)
    values.maturity = (values.prinInv * (interest ** e)).toLocaleString('en', {useGrouping:true})
    
    return {
        target: {
            type: "change",
            id: id,
            value: e
        }
    }
  }
    
  function handlePrinChange(e, values) {
    values.maturity = (e.target.value * (timeToInterest(values.time) ** values.time)).toLocaleString('en', {useGrouping:true})
  }

  function timeToInterest(time) {
    if (time === 14) return 1.03
    if (time === 30) return 1.04
    if (time === 50) return 1.05
    if (time === 70) return 1.06
    if (time === 90) return 1.07
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            ubucks: uBucks,
            prinInv: "",
            maturity: "",
            time: 14,
          }}
          validationSchema={Yup.object().shape({
            prinInv: Yup.number().integer().min(1).max(uBucks),
          })}

          onSubmit={(values, { setSubmitting }) => {
            let dataToSubmit = {
              prinInv: parseInt(values.prinInv),
              time: parseInt(values.time),
            };
            dispatch(CDTransaction(dataToSubmit)).then(() => {
              dispatch(getStudentUBucks())
              dispatch(getCDPortfolio())
              dispatch(getCDTransactions())
              setSubmitting(false);
            })
          }}

          onChange={(values) => {
          	console.log('something changed')
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div>
                <div className="form">
                  <h1>Invest</h1>
                  <Form 
                    {...formItemLayout}
                    onSubmit={handleSubmit}
                    labelCol={{ flex: '110px' }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                >
                    <Row>
                      <Col span={8} offset={8}>
                        <Form.Item label="UBucks">
                          <Input
                            readOnly
                            id="ubucks"
                            type="int"
                            value={values.ubucks}
                           />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Form.Item label="Principal Investment" validateStatus={errors.prinInv && touched.prinInv ? "error" : 'success'}>
                          <Input
                            style={{
                                width: 150,
                            }}
                            id="prinInv"
                            placeholder="How much to invest"
                            type="int"
                            value={values.prinInv}
                            onChange={e => {
                              handlePrinChange(e, values)
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                            errors.prinInv && touched.prinInv ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.prinInv && touched.prinInv ? <div className="input-feedback">{errors.prinInv}</div> : null}
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label="Time">
                          <Select
                            id="time"
                            defaultValue={14}
                            style={{
                              width: 170,
                            }}
                            onChange={e => {
                              handleChange(handleSelectChange(e, values, 'time'))
                            }}
                            options={[
                              {
                              value: 14,
                              label: '14 Days (3% DPY)',
                              },
                              {
                              value: 30,
                              label: '30 Days (4% DPY)',
                              },
                              {
                              value: 50,
                              label: '50 Days (5% DPY)',
                              },
                              {
                              value: 70,
                              label: '70 Days (6% DPY)',
                              },
                              {
                                value: 90,
                                label: '90 Days (7% DPY)',
                              },
                            ]}
                          />
                          <Popover 
                              content="Your money will be locked in for this time at the given Daily Percentage Yield...It will grow!"
                          >
                            <QuestionCircleTwoTone />
                          </Popover>
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label="Value at Maturity">
                          <Input
                            style={{
                                width: 150,
                            }}
                            readOnly
                            id="maturity"
                            value={values.maturity}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <MyGraph graphOptions = {makeGraphConfig(values)} />
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                    <Form.Item>
                      <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.prinInv || values.prinInv === ""}>
                        Submit
                      </Button>
                    </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>

        <div className="tableData">
          <h1>Portfolio</h1>
          <Table rowData={accounts} tableData={configTablePositions} />
          <h1>Transactions</h1>
          <Table rowData={transactionData} tableData={configTableTransactions} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(CdsPage)
