import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { getTeachers, regenAdminCode, kickTeacher } from "../../../_actions/user_actions";
import { Button, Popconfirm, Popover, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import Table from '../Table/Table'


function AddAdminPage(props) {
  const dispatch = useDispatch();
  const teacherClass = props.data.user.teacherClass
  const classMembers = props.data.user.teacherClass?.members ?? []
  const addCode = props.data.user.teacherClass?.addCode ?? "Loading"

  useEffect(() => {
    dispatch(getTeachers())
  }, [dispatch])

  const configTableAdmins = {
    onClick: (group) => {
      // console.log('leave the class?' + group.teacher)
    },
    tableHeadings: [
      'Teacher'
    ],
  }

  const RemoveComponent = localProps => {
    const { row } = localProps

    const removeOnClick = (e, row) => {
      dispatch(kickTeacher({_id: teacherClass._id, kick_id: row._id}))
      e.stopPropagation()
    }

    return (
      <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeOnClick(e, row)} title="You cannot give money without an admin, are you sure you want to remove them?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type="primary" danger>Remove</Button>            
      </Popconfirm>
    )
  }
    
  const updatedTeacherClass = classMembers.map((row, index) => {
    let newRow = {
      Teacher: row.lastName,
      remove: <RemoveComponent id={index} row={row} />,
    }
    return newRow
  })

  const regenClickHandler = () => {
    dispatch(regenAdminCode({_id: teacherClass._id})).then(response => {
      if (response.payload.addCode) {
      message.success("Teachers can now register for the next 72 Hours")
      message.error("Do not let students see this code", 10)
      }
      else message.error("Your add Code is not regenerating")
    })
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <div id='adminCenter'>
          <h1>Teacher Add Code</h1>
          <h1>{addCode}</h1>
          <Button onClick={regenClickHandler} type="primary">Regen Code
            <Popover content="If your teachers can't register then regen your add code">
              <QuestionCircleTwoTone />
            </Popover>
          </Button>
        </div>
        <div className="tableData">
          <h1>Added Teachers</h1>
          <Table rowData={updatedTeacherClass} tableData={configTableAdmins} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(AddAdminPage))
