import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Ticker from 'react-ticker'
import { getBucks, getCryptos } from "../../../_actions/user_actions";
import { Affix } from 'antd';

function MyTickers(props) {

  const dispatch = useDispatch();
  let bucks = props.data.user?.bucks ?? ["Loading"]
  let cryptos = props.data.user?.cryptos ?? ["Loading"]
  useEffect(() => {
    dispatch(getBucks())
    dispatch(getCryptos())
  }, [dispatch])

  if (bucks.length < 3 || cryptos.length < 3 || typeof(bucks) === "string" || typeof(bucks) === "string") {
    return null
  }

  else {

    let start = Math.floor(Math.random() * cryptos.length)
    
    return (
      <>
        <Affix offsetBottom={0}>
        <Ticker height={33.3} speed={8}>
          {({ index }) => (
              <>
                <h2 style={{ paddingRight: "0.5em", backgroundColor: "#b3f7a8"}}>
                  {cryptos[(index + start) % cryptos.length].name}: {cryptos[(index + start) % cryptos.length].price}
                </h2>
              </>
            )}
          </Ticker>
          <Ticker height={33.3} speed={5}>
          {({ index }) => (
              <>
                <h2 style={{ paddingRight: "0.5em", backgroundColor: "#f7a8a8" }}>
                  {bucks[(index + start) % bucks.length].name}: {bucks[(index + start) % bucks.length].ratio.toFixed(3)}
                </h2>
              </>
            )}
          </Ticker>
        </Affix>
      </>
    );
  }

}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(MyTickers)
