/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu, message } from 'antd';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";

function RightMenu(props) {
  const user = useSelector(state => state.user)

  const logoutHandler = () => {
    axios.get(`/auth/al/logout`).then(response => {
      if (response.status === 200) {
        props.history.push("/login");
      } else {
        message.error('Log Out Failed')
      }
    });
  };

  if (user.userData && !user.userData.isAuth) {
    const items = [
      { label: (<a href="/login">Sign In</a>), key: 'mail' },
      { label: (<a href="/register">Sign Up</a>), key: 'app' },
    ];
    return <Menu disabledOverflow={true} mode={props.mode} items={items} />;
  } else {
    const items = [
      { label: (<a onClick={logoutHandler}>Logout</a>), key: 'logout' },
    ];
    return <Menu disabledOverflow={true} mode={props.mode} items={items} />;
  }
}

export default withRouter(RightMenu);

