import React, { useEffect } from 'react';
import { Menu, Badge } from 'antd';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getMarketPurchases } from "../../../../_actions/user_actions";

function LeftMenu(props) {

  const dispatch = useDispatch();

  const userData = props.data?.user.userData ?? ["Loading Data"]
  let soldItems = props.data.user?.purchases ?? ["Loading"]
  if (soldItems.count > 0 ) {
    if (!document.title.startsWith('🔴 ')){
      document.title = '🔴 ' + document.title;
    } 
  }
  else {
    document.title = document.title.replace('🔴 ', '');
  }
  
  useEffect(() => {
    let intervalId
    if (userData.role === 1) {
      dispatch(getMarketPurchases())
    
      intervalId = setInterval(() => {
        dispatch(getMarketPurchases())
      }, 60000);
    }

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 600000);

    // Cleanup interval and timeout on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };

  }, [dispatch, userData.role])

  const auctionAddress = () => {
    if (userData.role === 0) return "/student/auctions/" + userData._id
    if (userData.role === 1) return "/teacher/auctions/" + userData._id
    if (userData.role === 2) return "/prin/auctions/" + userData._id
  }

  const addAddress = () => {
    if (userData.role === 0) return "/student/addClass/" + userData._id
    if (userData.role === 2) return "/prin/approved/" + userData._id
  }

  const addHeading = () => {
    if (userData.role === 0) return "Add Class"
    if (userData.role === 2) return "Approved Teachers"
  }

  const Navigation = () => {
    if (!userData.isAuth) return null
    else if (userData.role === 0) return studentNavigation()
    else if (userData.role === 1) return teacherNavigation()
    else if (userData.role === 2) return adminNavigation()
  }

  const studentNavigation = () => {
    const items = [
      { label: (<a href="/student/users">Users</a>), key: 'users' },
      { label: 'Money', key: 'subMoney',
        children: [
          {
            label: <a href={"/student/savings/" + userData._id}>Savings</a>,
            key: 'savings',
          },
          {
            label: <a href={"/student/cds/" + userData._id}>Certificate of Deposit</a>,
            key: 'cds',
          },
          {
            label: <a href={"/student/cryptos/" + userData._id}>Cryptos</a>,
            key: 'cryptos',
          },
          {
            label: <a href={"/student/raffle/" + userData._id}>Raffle</a>,
            key: 'raffle',
          },
        ],
      },
      { label: 'Market', key: 'subMarket',
        children: [
          { label: (<a href={auctionAddress()}>View Auctions</a>), key: 'viewAuctions' },
          { label: (<a href={"/teacher/auctions/" + userData._id}>Make Auctions</a>), key: 'makeAuctions' },
          { label: (<a href="/student/store">Store</a>), key: 'store' },
        ],
      },
      { label: (<a href={addAddress()}>{addHeading()}</a>), key: 'addClass' },
    ];
    return <Menu disabledOverflow={true} mode={props.mode} items={items} />;
  }

  const teacherNavigation = () => {
    const items = [
      { label: (<a href="/student/users">Users</a>), key: 'users' },
      { label: (<a href="/teacher/classes">Classes</a>), key: 'classes' },
      { label: (
        <span>
        Market
        {soldItems.count > 0 && <Badge dot style={{ marginLeft: 8, backgroundColor: 'red' }} />}
      </span>
      ),
      key: 'subMarket',
        children: [
          { label: (<a href={auctionAddress()}>Make Auctions</a>), key: 'viewAuctions' },
          { label: (<a href="/teacher/approveAuctions">Approve Auctions</a>), key: 'approveAuctions' },
          { label: (<a href="/teacher/store">Store
            {soldItems.count > 0 && <Badge count={soldItems.count} style={{ marginLeft: 8, backgroundColor: 'red' }} />}
          </a>), key: 'store' },
        ],
      },
      { label: 'Money', key: 'subMoney',
        children: [
          { label: (<a href="/teacher/bucks">Bucks</a>), key: 'Bucks' },
          { label: (<a href="/teacher/transactions">Transactions</a>), key: 'Transactions' },
          { label: (<a href="/teacher/events">Events</a>), key: 'Events' },
        ],
      },
      { label: (<a href="/teacher/settings">Settings</a>), key: 'Settings' },
    ];
    return <Menu disabledOverflow={true} mode={props.mode} items={items} />;
  }

  const adminNavigation = () => {
    const items = [
      { label: (<a href="/student/users">Users</a>), key: 'users' },
      { label: 'Classes', key: 'subClasses',
        children: [
          { label: (<a href="/teacher/classes">My Classes</a>), key: 'classes' },
          { label: (<a href={addAddress()}>{addHeading()}</a>), key: 'addClass' },
        ],
      },
      { label: 'Market', key: 'subMarket',
        children: [
          { label: (<a href={auctionAddress()}>Make Auctions</a>), key: 'viewAuctions' },
          { label: (<a href="/teacher/approveAuctions">Approve Auctions</a>), key: 'approveAuctions' },
          { label: (<a href="/teacher/store">Store</a>), key: 'store' },
        ],
      },
      { label: 'Money', key: 'subMoney',
        children: [
          { label: (<a href="/teacher/bucks">Bucks</a>), key: 'Bucks' },
          { label: (<a href="/teacher/transactions">Transactions</a>), key: 'Transactions' },
          { label: (<a href="/teacher/events">Events</a>), key: 'Events' },
        ],
      },
      { label: (<a href="/prin/settings">Settings</a>), key: 'Settings' },
    ];
    return <Menu disabledOverflow={true} mode={props.mode} items={items} />;
  }

  return (
    <Navigation />
  )
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(LeftMenu))