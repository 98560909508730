import React from 'react'
import MyTickers from "../Utilities/MyTickers"

function Footer() {
    return (
        <>
            <MyTickers />
        </>
    )
}

export default Footer
