import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { getSettings, getStudentCount, setSettings, getTaxBrackets, executeTax } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, Col, message, Popover, Modal } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 30 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

function SettingsPage(props) {
  const dispatch = useDispatch();
  const formRef = useRef();
  const settings = props.data.user?.settings ?? {
    student2student: true,
    lottery: true,
    odds: 5000
  }
  const taxBrackets = props.data.user?.taxBrackets ?? []

  let formikSetter = null

  useEffect(() => {
  	dispatch(getSettings())
  }, [dispatch])
  
  const outsideSetValueHandler = (values, setFieldValue) => {
    formikSetter = setFieldValue
  }

  const handleSettingToggle = (student2student, lottery, odds, fromLotto) => {   
    const dataToSubmit = {
      student2student: student2student,
      lottery: lottery,
      odds: parseInt(odds)
    }
    dispatch(setSettings(dataToSubmit)).then(response => {

      if (response.payload) {
        message.error(response.payload.message)
      }
      else {
        dispatch(getSettings()).then(response => {
          if (fromLotto && formRef.current.values.lottery) {
            dispatch(getStudentCount({schoolId: props.data.user.userData.school_id})).then(response => {
              formikSetter('odds', response.payload.count * 10)
              formikSetter('saveButton', true)
            })

          }
        })
        message.success("settings updated")
      }
    })
  }	
  
  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={{
        student2student: settings.student2student,
        odds: settings.odds,
        lottery: settings.lottery,
        saveButton: false,
        tax: false,
        taxRate: 0,
      }}

      validationSchema={Yup.object().shape({
        odds: Yup.number().integer().min(1).max(9999999999).required('An integer greater than 1 is required'),
        taxRate: Yup.number().integer().min(0).max(100)
      })}

      onSubmit={(values, { setSubmitting }) => {
        console.log("submit")
        handleSettingToggle(values.student2student, values.lottery, values.odds, false)
        setSubmitting(false)
        values.saveButton = false
      }}

    >
      {props => {
        const {
          values,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        } = props;

        return (
      		<div className="body">
	            <div className='form'>
	              	<h1>Settings</h1>
	              	<Form {...formItemLayout} onSubmit={handleSubmit}>
                  {outsideSetValueHandler(values, setFieldValue)}

                  <Row>
                      <Col span={12}>
                        <Form.Item label="Tax">
                          <label className="switch">
                            <input
                              id="tax"
                              type="checkbox"
                              onChange={e => {
                                values.tax = !values.tax
                                if (values.tax === false) {values.taxRate = 0}
                                handleChange(e)
                              }}
                              checked={values.tax}
                            />
                            <span className="slider round"></span>
                          </label>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item hidden = {!values.tax} label="Flat Tax Rate" validateStatus={errors.taxRate && touched.taxRate ? "error" : 'success'}>
                          <Input
                            id="taxRate"
                            type="int"
                            value={values.taxRate}
                            onChange={e => {
                              handleChange(e)
                            }}
                            suffix= {
                              <Popover content={"0 means an auto balanced progressive tax will be implemented to all students"}>
                                <QuestionCircleTwoTone />
                              </Popover>
                            }
                            onBlur={handleBlur}
                            className={
                              errors.taxRate && touched.taxRate ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.taxRate && touched.taxRate && (
                            <div className="input-feedback">{errors.taxRate}</div>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row type="flex" justify="center" align="middle">
                      <Form.Item hidden = {!values.tax || errors.taxRate}>
                        <TaxModal values={values} isSubmitting={isSubmitting} formikSetter={formikSetter} taxBrackets={taxBrackets}/>  
                      </Form.Item>
                    </Row>
		                


		                <Row hidden={values.tax}>
                      <Form.Item label="Student To Student Transactions">
                        <label className="switch">
                          <input 
                            id="student2student"
                            type="checkbox"
                            onChange={e => {
                              values.student2student = !values.student2student
                              handleSettingToggle(values.student2student, values.lottery, values.odds, false)
                            }}
                            checked={values.student2student} 
                          />
                          <span className="slider round"></span>
                        </label>
                      </Form.Item>
		                </Row>
                    <Row hidden={values.tax}>
                      <Col span={12}>
                        <Form.Item label="Raffle">
                          <label className="switch">
                            <input
                              id="lottery"
                              type="checkbox"
                              onChange={e => {
                                values.lottery = !values.lottery
                                handleSettingToggle(values.student2student, values.lottery, values.odds, true)
                              }}
                              checked={values.lottery}
                            />
                            <span className="slider round"></span>
                          </label>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item hidden = {!values.lottery} label="Raffle winnings 1 in" validateStatus={errors.odds && touched.odds ? "error" : 'success'}>
                          <Input
                            id="odds"
                            placeholder="1-999999999"
                            type="int"
                            value={values.odds}
                            onChange={e => {
                              if (parseInt(e.target.value) === settings.odds) values.saveButton = false
                              else values.saveButton = true
                              handleChange(e)
                            }}
                            suffix= {
                              <Popover content={"Higher number means more time before winner is selected"}>
                                <QuestionCircleTwoTone />
                              </Popover>
                            }
                            onBlur={handleBlur}
                            className={
                              errors.odds && touched.odds ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.odds && touched.odds && (
                            <div className="input-feedback">{errors.odds}</div>
                          )}
                        </Form.Item>
                      </Col>
		                </Row>

                    <Row hidden={values.tax} type="flex" justify="center" align="middle">
                    <Form.Item hidden = {!values.saveButton || errors.odds}>
                      <Button onClick={handleSubmit} type="primary" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </Form.Item>
                    </Row>
                    

	               	</Form>
	            </div>
          	</div>
        );
      }}
    </Formik>
  );
}

const TaxModal = ({values, isSubmitting, formikSetter, taxBrackets}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleTaxSubmit = (taxRate) => {
    dispatch(executeTax({taxRate: parseInt(taxRate)})).then(response => {
      formikSetter('tax', false)

      if (response.payload) {
        message.error(response.payload)
      }
      else {
        message.success("All Users Taxed")
      }
      
    })
  }

  const showModal = () => {
    if (values.taxRate === 0) {
      dispatch(getTaxBrackets())
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleTaxSubmit(values.taxRate)
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button onClick={showModal} type="primary" disabled={isSubmitting}>
        Submit
      </Button>
      <Modal title="Tax Brackets" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {values.taxRate > 0 ? 
          <p>Are you sure you want to execute a flat tax on all students at {values.taxRate}%?</p>
          :
          <>
            <p>Under {taxBrackets[0]?.bracket} at {taxBrackets[0]?.rate * 100}%</p>
            <p>Under {taxBrackets[1]?.bracket} at {taxBrackets[1]?.rate * 100}%</p>
            <p>Under {taxBrackets[2]?.bracket} at {taxBrackets[2]?.rate * 100}%</p>
            <p>Under {taxBrackets[3]?.bracket} at {taxBrackets[3]?.rate * 100}%</p>
            <p>Under {taxBrackets[4]?.bracket} at {taxBrackets[4]?.rate * 100}%</p>
            <p>Under {taxBrackets[5]?.bracket} at {taxBrackets[5]?.rate * 100}%</p>
            <p>Over {taxBrackets[5]?.bracket} at 37%</p>
          </>
        }
        
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(SettingsPage))