import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { getClassMembers, removeAdmin, addAdmin } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import { Form, Input, Button, Row, Popconfirm } from 'antd';
import Table from '../Table/Table'

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 8
  },
};
const tailLayout = {
  wrapperCol: {
    span: 16,
  },
};

function AddAdminPage(props) {
  const dispatch = useDispatch();
  const userData = props.data.user?.userData ?? []
  const classesData = props.data.user?.classes ?? []

  useEffect(() => {
    dispatch(getClassMembers())    
  }, [dispatch])

  const configTableAdmins = {
    onClick: (group) => {
      // console.log('leave the class?' + group.teacher)
    },
    tableHeadings: [
      'admin', 'remove'
    ],
  }

  const RemoveComponent = localProps => {
    const { row } = localProps

    const removeOnClick = (e, row) => {
    	let dataToSubmit = {_id: row._id, kick_id: userData._id}
      dispatch(removeAdmin(dataToSubmit))
      e.stopPropagation()
    }

    return (
      <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeOnClick(e, row)} title="You cannot give money without an admin, are you sure you want to remove them?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type="primary" danger>Remove</Button>            
      </Popconfirm>
    )
  }
    
  const updatedClassesData = classesData.map((row, index) => {
  	row.admin = row.owner_id.firstName + ' ' + row.owner_id.lastName
    row.remove = <RemoveComponent id={index} row={row} />
    return row
  })
  
  return (
  	<div className="body">
      <div className="innerBody">
		    <Formik
		      enableReinitialize
		      initialValues={{
		        addCode: '',
		      }}

		      onSubmit={(values, { setSubmitting }) => {
	          const path = props.location.pathname
	          const n = path.lastIndexOf('/');
	          const short_id = path.substring(n + 1);

	          let dataToSubmit = {
	            addCode: values.addCode,
	            _id: short_id
	          };

	          dispatch(addAdmin(dataToSubmit)).then((response) => {
	          	values.addCode = ""
	          	setSubmitting(false);
	          })
		      }}
		    >

		      {props => {
		        const {
		          values,
		          isSubmitting,
		          handleChange,
		          handleSubmit
		        } = props;

		        return (
		          <div>
		            <div className='form'>
		              <h1>Adding an Administrator</h1>
		              <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Form.Item label="Class Code">
                      <Input
                        id="addCode"
                        type="text"
                        value={values.addCode}
                        onChange={handleChange}
                      />
                  	</Form.Item>
                  	<Row type="flex" justify="center" align="middle">
	                  	<Form.Item {...tailLayout}>
	                  		<Button onClick={handleSubmit} type="primary" disabled={isSubmitting || values.addCode === ""}>
			                    Submit
			                  </Button>
			                </Form.Item>
			               </Row>
		              </Form>
		            </div>
		          </div>
		        );
		      }}
		    </Formik>

		    <div className="tableData">
		      <h1>Added Administrators</h1>
		      <Table rowData={updatedClassesData} tableData={configTableAdmins} />
		    </div>
		  </div>
		</div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(AddAdminPage))
