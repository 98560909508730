import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getLottoLatest, getLottoPrevious, getStudentUBucks, purchaseLotto, auth } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, Col, message, Image } from 'antd';
import Table from '../Table/Table'
import './Money.css'
import image from '../../../assets/hockeyKid.gif'

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

function LottoPage(props) {
  const dispatch = useDispatch();

  let userDetails = {}
  userDetails.lottoPlay = props.data.user.userData?.lottoPlay ?? 1
  userDetails.lottoWin = props.data.user.userData?.lottoWin ?? 0

  const lottoPrevious = props.data.user?.lottoPrevious ?? {
    winner: "",
    jackpot: 0,
  }

  const lottoLatest = props.data.user?.lottoLatest ?? {
    jackpot: 0,
    odds: 0
  }
  const uBucks = props.data.user?.buck ?? 0

  useEffect(() => {
    dispatch(getLottoPrevious())
    dispatch(getLottoLatest())
    dispatch(getStudentUBucks())

  }, [dispatch])

  const configTableStats = {
    tableHeadings: [
      'Winnings', 'Losses', 'Percent'
    ],
  }

  let statsData = [{
    Winnings: userDetails.lottoWin,
    Losses: userDetails.lottoPlay,
    Percent: ((userDetails.lottoWin / userDetails.lottoPlay) * 100).toFixed(2) + "%"
  }]

  function CelebrationImage() {
    return (
      <Image
        src={image}
      />
    )
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Row type="flex" justify="center" align="middle">
          <Col span={12}>
            <h1>Last Winner: {lottoPrevious.winner}</h1>
          </Col>
          <Col span={12}>
            <h1>Last Winnings: {lottoPrevious.jackpot}</h1>
          </Col>
        </Row>
        <Formik
          enableReinitialize
          initialValues={{
            value: lottoLatest.jackpot,
            odds: lottoLatest.odds,
            ubucks: uBucks.value,
            quantity: '',
            cost: '',
          }}
          validationSchema={Yup.object().shape({
            quantity: Yup.number().integer().min(1).max(99999999),
          })}

          onSubmit={(values, { setSubmitting }) => {
            dispatch(purchaseLotto(values.quantity)).then(response => {
              dispatch(getLottoLatest())
              dispatch(getStudentUBucks())
              dispatch(auth())
              if (response.payload === true) {
                dispatch(getLottoPrevious())
                message.open({
                  content: <CelebrationImage />,
                  duration: 7,
                  style: {
                    marginTop: '30vh',
                  },
                })
              }
              else if (response.payload !== true && response.payload.length > 5) {
                message.error(response.payload)
              } 
              else {
                message.error("Try again")
              }

              setSubmitting(false);
            })
          }}

          onChange={(values) => {
          	console.log('something changed')
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
            } = props;

            return (
              <div>
                <div className="form">
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <h1>Jackpot</h1>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Value">
                          <Input
                            readOnly
                            id="value"
                            type="int"
                            value={values.value}
                            className={'text-input'}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Odds">
                          <Input
                            readOnly
                            id="odds"
                            type="int"
                            value={values.odds}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <h1>Purchase Tickets</h1>
                    <Row type="flex" justify="center" align="middle">
                      <Form.Item label="Ubucks">
                        <Input
                          readOnly
                          id="ubucks"
                          type="int"
                          value={values.ubucks}
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Quantity" validateStatus={errors.quantity && touched.quantity ? "error" : 'success'}>
                          <Input
                            id="quantity"
                            placeholder="How many would you like to buy?"
                            type="int"
                            value={values.quantity}
                            onChange={e => {
                            	values.cost = e.target.value * 5
                            	handleChange(e)
                            }}
                          />
                          {isNaN(values.quantity) || values.quantity < 0 ? <span className="input-feedback">You can only use positive whole numbers here</span> : null}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Cost after tax">
                          <Input
                            readOnly
                            id="cost"
                            type="int"
                            value={values.cost}
                          />
                          {values.cost > values.ubucks ? <p className="input-feedback">Thats more Ubucks than you have</p> : null}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                    <Form.Item hidden = {values.cost > values.ubucks || values.quantity === 0 || values.quantity === "" || errors.cost || isSubmitting}>
                      <Button onClick={handleSubmit} type="primary" disabled={isSubmitting}>
                        Buy
                      </Button>
                    </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>

        <div className="tableData">
          <h1>Stats</h1>
          <Table rowData={statsData} tableData={configTableStats} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(LottoPage)
