import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { searchCrypto, cryptoDetails, cryptoTransaction, cryptoHistory, clearSymbol, symbolLookup } from "../../../_actions/user_actions";
import { Form, Input, Button, Row, Col, message } from 'antd';
import Table from '../Table/Table'
import './Money.css'
import moment from 'moment'

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

const map = {
  bitcoin: "btc",
  cardano: "ada",
  ethereum: "eth",
  chainlink: "link",
  solana: "sol",
  dogecoin: "doge",
  polkadot: "dot",
  dai: "dai",
  polygon: "matic",
  tron: "trx",
  avalanche: "avax",
  okb: "okb",
  litecoin: "ltc",
  cronos: "cro",
  uniswap: "uni",
  stellar: "xlm",
  algorand: "algo",
  flow: "flow",
  vechain: "vet",
  filecoin: "fil",
  frax: "frax",
  apecoin: "ape",
  hedera: "hbar",
  eos: "eos",
  decentraland: "mana",
  tezos: "xtz",
  quant: "qnt",
  aave: "aave",
  kucoin: "kcs",
  zcash: "zec",
  helium: "hnt",
  fantom: "ftm"
}

function CryptoPage(props) {
  const dispatch = useDispatch();
  const crypto = props.data.user?.crypto ?? {searched: 'loading', usd: 5, owned: 5, UBuck: 9}
  const cryptoAccounts = props.data.user?.cryptoAccounts ?? []
  const cryptoTransactions = props.data.user?.cryptoHistory ?? []
  const cryptoLink = props.data.user?.cryptoSymbol ?? ""

  useEffect(() => {
    dispatch(cryptoDetails())
    dispatch(cryptoHistory())
    dispatch(searchCrypto({name: 'cardano'}))
  }, [dispatch])

  const configTablePositions = {
    onClick: (crypto) => {
      console.log('dispatch a search for ' + crypto.name)
    },
    tableHeadings: [
      'name', 'percentChange', 'profit', 'quantity', 'currentValue', 'currentPrice', 'basis', 'spent'
    ],
  }

  let accounts = cryptoAccounts.map((account, index) => {
    let gains = (account.currentPrice - account.basis) > 0
    let newPercent
    if (gains) {
      let percentChange = (account.currentPrice / account.basis) - 1
      let adjustedChange = percentChange * 3
      newPercent = 1 + adjustedChange
    }
    else {
      let percentChange = account.currentPrice / account.basis
      newPercent = percentChange ** 3
    }
    let spent = account.basis * account.quantity
    let currentValue = newPercent * account.basis * account.quantity
    let tempAccount = {}
    tempAccount.name = account.name
    tempAccount.spent = spent.toLocaleString('en', {useGrouping:true})
    tempAccount.percentChange = ((newPercent - 1) * 100).toFixed(2).toLocaleString('en', {useGrouping:true}) + "%"
    tempAccount.currentPrice = account.currentPrice.toLocaleString('en', {useGrouping:true})
    tempAccount.currentValue = (currentValue).toLocaleString('en', {useGrouping:true})
    tempAccount.profit = (currentValue - spent).toFixed(2).toLocaleString('en', {useGrouping:true})
    tempAccount.quantity = account.quantity.toLocaleString('en', {useGrouping:true})
    tempAccount.basis = account.basis.toLocaleString('en', {useGrouping:true})
    return tempAccount
  })

  const configTableTransactions = {
    onClick: (user) => {
      // props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'name', 'date', 'description', 'price', 'value', 'ubucks', 'balance'
    ],
  }

  let transactionData = cryptoTransactions.map((transaction, index) => {
    let tempTransaction = {}
    tempTransaction.name = transaction.name
    tempTransaction.date = moment(transaction.createdAt).format('YYYY-MM-DD HH:mm')
    tempTransaction.description = transaction.description
    tempTransaction.value = transaction.amount.toLocaleString('en', {useGrouping:true})
    tempTransaction.ubucks = transaction.uBucks.toLocaleString('en', {useGrouping:true})
    tempTransaction.price = transaction.conversionRatio.toLocaleString('en', {useGrouping:true})
    tempTransaction.balance = transaction.balance.toLocaleString('en', {useGrouping:true})
    return tempTransaction
  })

  const profitCalc = (amount) => {
    let gains = (crypto.usd - crypto.basis) > 0
    let newPercent
    if (gains) {
      let percentChange = crypto.usd / crypto.basis - 1
      let adjustedChange = percentChange * 3
      newPercent = 1 + adjustedChange
    }
    else {
      let percentChange = crypto.usd / crypto.basis
      newPercent = percentChange ** 3
    }
    let spent = crypto.basis * amount
    
    let currentValue = newPercent * spent
    return currentValue.toFixed(4)
  }

  const handleSearch = name => {
    dispatch(searchCrypto({name: name})).then(response => {
      if (!response.payload.searchSuccess && response.payload.message) {
        message.error(response.payload.message)
      } 
    })

    dispatch(clearSymbol())

    let symbol = map[name]
    if (symbol !== undefined) {
      dispatch(symbolLookup(symbol.toLowerCase()))
    }
    
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            name: crypto.searched,
            price: crypto.usd,
            owned: crypto.owned || 0,
            ubucks: crypto.UBuck,
            buy: 0,
            charge: 0,
            sell: 0,
            uBuckChange: 0,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required('name is required'),
            price: Yup.number().positive(),
            owned: Yup.number().min(0),
            ubucks: Yup.number(),
            charge: Yup.number().min(0),
            buy: Yup.number().min(0).max(99999999),
            sell: Yup.number().min(0).max(99999999),
            uBuckChange: Yup.number(),
          })}

          onSubmit={(values, { setSubmitting }) => {
            console.log("submit")
            let dataToSubmit = {
              name: values.name,
              buy: parseFloat(values.buy),
              sell: parseFloat(values.sell)
            };
            dispatch(cryptoTransaction(dataToSubmit)).then(() => {
              dispatch(cryptoDetails())
              dispatch(cryptoHistory())
              handleSearch(values.name)
              setSubmitting(false);
            })
          }}

          onChange={(values) => {
          	console.log('something changed')
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div>
                <div className="form">
                  <h1>Buy or Sell</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Name">
                          <Input
                            id="name"
                            placeholder="Enter the crypto name"
                            type="text"
                            value={values.name}
                            onChange={e => {
                              values.price = 0
                              values.owned = 0
                              values.buy = 0
                              values.sell = 0
                              values.uBuckChange = 0
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.name && touched.name ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.name && touched.name && (
                            <div className="input-feedback">{errors.name}</div>
                          )}
                        </Form.Item>
                      </Col>
                      <Button hidden={values.name === "" || values.name === null || values.name === undefined} type="primary" onClick={() => handleSearch(values.name)}>Search</Button>
                      {values.price === 0 ? <div className="input-feedback">You need to hit search</div> : null}
                      <Col span={12}>
                        <Form.Item label="UBuck Change">
                          <Input
                            readOnly
                            id="uBuckChange"
                            type="int"
                            value={values.uBuckChange}
                          />
                        </Form.Item>
                      </Col>
                      {cryptoLink === "" || values.price === 0 ? null : 
                        <Col hidden={values.name === undefined} span={12}>
                          <Button type="primary">
                            <a className="flash" href={"https://finance.yahoo.com/quote/"+cryptoLink+"-usd"} target="_blank" rel="noreferrer">{values.name + " Research"}</a>
                          </Button>
                        </Col>
                      }
                      
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Price">
                        <Input
                          readOnly
                          id="price"
                          type="int"
                          value={values.price}
                        />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Owned">
                          <Input
                            readOnly
                            id="owned"
                            type="int"
                            value={values.owned}
                           />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="UBucks">
                          <Input
                            readOnly
                            id="ubucks"
                            type="int"
                            value={values.ubucks}
                           />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="charge">
                          <Input
                            readOnly
                            id="charge"
                            type="int"
                            value={values.charge}
                           />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Buy" validateStatus={errors.buy && touched.buy ? "error" : 'success'}>
                          <Input
                            id="buy"
                            placeholder="How many to buy"
                            type="int"
                            value={values.buy}
                            onChange={e => {
                            	values.sell = 0
                              values.uBuckChange = -(e.target.value * values.price) * 1.01
                              values.charge = e.target.value * values.price * .01
                            	handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.buy && touched.buy ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.buy && touched.buy ? <div className="input-feedback">{errors.buy}</div> : null}
                          { (values.sell === 0 && values.uBuckChange + values.ubucks) < 0 ? <div className="input-feedback">UBucks spent cannot be greater than UBucks</div> : null}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Sell" validateStatus={errors.sell && touched.sell ? "error" : 'success'}>
                          <Input
                            id="sell"
                            placeholder="How many to sell"
                            type="int"
                            value={values.sell}
                            onChange={e =>{
                            	values.buy = 0
                              values.uBuckChange = profitCalc(e.target.value) * .99
                              values.charge = e.target.value * values.price * .01
                            	handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.sell && touched.sell ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.sell && touched.sell && (
                            <div className="input-feedback">{errors.sell}</div>
                          )}
                          { values.sell > values.owned ? <div className="input-feedback">Sell cannot be greater than owned</div> : null}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                    <Form.Item hidden = {values.sell > values.owned || (values.sell === 0 && values.uBuckChange + values.ubucks) < 0 || values.price === 0 || errors.name || errors.sell || (values.sell === 0 && values.buy === 0)}>
                      <Button onClick={handleSubmit} type="primary" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>

        <div className="tableData">
          <h1>Portfolio</h1>
          <Table rowData={accounts} tableData={configTablePositions} />
          <h1>Transactions</h1>
          <Table rowData={transactionData} tableData={configTableTransactions} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(CryptoPage)
