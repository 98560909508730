import React, { useEffect } from "react";
import { connect } from "react-redux";
import {  } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Button, Row, Col } from 'antd';
import Table from '../Table/Table'
import './Money.css'
import mockData from '../User/mockData.json' //this is mock data delete it later

const formItemLayout = {
  labelCol: {
    xs: { span: 44 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

function SecuritiesPage(props) {

  useEffect(() => {
    
  }, [])

  const configTablePositions = {
    onClick: (security) => {
      console.log('dispatch a search for ' + security.symbol)
    },
    tableHeadings: [
      'symbol', 'last price', 'total gain/loss', 'current value', '% of account', 'quantity', 'cost basis'
    ],
  }

  const configTableTransactions = {
    onClick: (user) => {
      // props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'date', 'symbol', 'price', 'quantity', 'value'
    ],
  }

  const handleSearch = symbol => {
  	console.log('searched for ' + symbol)
  }
  
  return (
    <div className="body">
      <div className="innerBody">
        <Formik
          enableReinitialize
          initialValues={{
            symbol: 'goog',//use dynamic values
            price: 100,
            owned: 200,
            ubucks: 1000,
            buy: 0,
            cost: 0,
            sell: 0,
          }}
          validationSchema={Yup.object().shape({
            symbol: Yup.string()
              .required('symbol is required'),
            price: Yup.number().positive(),
            owned: Yup.number().integer().min(0),
            ubucks: Yup.number().min(0),
            cost: Yup.number().min(0),
            buy: Yup.number().integer().min(0),
            sell: Yup.number().integer().min(0),
          })}

          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              console.log("tried to buy")
              //dispatch()//call the action to buy or sell stock
              setSubmitting(false);
            }, 500);
          }}

          onChange={(values) => {
          	console.log('something changed')
          }}
        >

          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <div>
                <div className="form">
                  <h1>Buy or Sell</h1>
                  <Form {...formItemLayout} onSubmit={handleSubmit}>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Symbol">
                          <Input
                            id="symbol"
                            placeholder="Enter the company symbol"
                            type="text"
                            value={values.symbol}
                            onChange={e => {
                              values.price = 0
                              values.owned = 0
                              values.buy = 0
                              values.sell = 0
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.symbol && touched.symbol ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.symbol && touched.symbol && (
                            <div className="input-feedback">{errors.symbol}</div>
                          )}
                        </Form.Item>
                      </Col>
                      <Button type="primary" onClick={() => handleSearch(values.symbol)}>Search</Button>
                      {values.price === 0 ? <div className="input-feedback">You need to hit search</div> : null}
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Price">
                        <Input
                          readOnly
                          id="price"
                          type="int"
                          value={values.price}
                        />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Owned">
                          <Input
                            readOnly
                            id="owned"
                            type="int"
                            value={values.owned}
                           />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="UBucks">
                          <Input
                            readOnly
                            id="ubucks"
                            type="int"
                            value={values.ubucks}
                           />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Cost">
                          <Input
                            readOnly
                            id="cost"
                            type="int"
                            value={values.buy * values.price}
                           />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Buy" validateStatus={errors.buy && touched.buy ? "error" : 'success'}>
                          <Input
                            id="buy"
                            placeholder="How many to buy"
                            type="int"
                            value={values.buy}
                            onChange={e =>{
                            	values.sell = 0
                            	handleChange(e)
                            }}
                            onBlur={handleBlur}
                            className={
                              errors.buy && touched.buy ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.buy && touched.buy && (
                            <div className="input-feedback">{errors.buy}</div>
                          )}
                          { values.buy * values.price > values.ubucks ? <div className="input-feedback">Cost cannot be greater than UBucks</div> : null}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Sell" validateStatus={errors.sell && touched.sell ? "error" : 'success'}>
                          <Input
                            id="sell"
                            placeholder="How many to sell"
                            type="int"
                            value={values.sell}
                            onChange={e =>{
                            	values.buy = 0
                            	handleChange(e)
                            }}
                            onBlur={e => {
                            	errors.oversell = true
                            	handleBlur(e)
                            }}
                            className={
                              errors.sell && touched.sell ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.sell && touched.sell && (
                            <div className="input-feedback">{errors.sell}</div>
                          )}

                          { values.sell > values.owned ? <div className="input-feedback">Sell cannot be greater than own</div> : null}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle">
                      <Form.Item hidden = {values.sell > values.owned || values.ubucks < values.buy * values.price || values.price === 0}>
                        <Button onClick={handleSubmit} type="primary" disabled={isSubmitting || errors.symbol || errors.buy || errors.sell}>
                          Submit
                        </Button>
                      </Form.Item>
                    </Row>
                    
                  </Form>
                </div>
                
              </div>
            );
          }}
        </Formik>

        <div className="tableData">
          <h1>Portfolio</h1>
          <Table rowData={mockData.stockData} tableData={configTablePositions} />
          <h1>Transactions</h1>
          <Table rowData={mockData.stockTransactions} tableData={configTableTransactions} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default connect(mapStateToProps)(SecuritiesPage)
