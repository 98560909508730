import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import { Form, Button, Input, Popover, Popconfirm, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { regenCode, selectClass, kickUser, postTransaction, postTransactions } from "../../../_actions/user_actions";
import './Teacher.css'

const FormItem = Form.Item;

function ClassPage(props) {
  const dispatch = useDispatch();
  const userData = props.data.user?.userData ?? []
  const classData = props.data.user?.class ?? ''
  const members = classData?.members ?? []

  const payClick = (preset, amount, row, form, visibleController) => {
    return function() {
      if (preset) { //pay 1 student with preset button
        dispatch(postTransaction(
          {
            amount: amount,
            description: "preset payment",
            owner_id: userData._id,
            student: row._id
          }
        )).then(response => {
          if (response.payload === "") {
          message.success(amount > 0 ? "User Paid" : "User Deducted")
          }
          else message.error(response.payload, 10)
        })
      }
      else {
        if (row._id === 'all') { //pay whole class with custom
          dispatch(postTransactions(
            {
              amount: parseFloat(form.getFieldValue('pay')),
              description: form.getFieldValue('description'),
              owner: userData._id,
              students: members.map((member, index) => {
                return member._id
              })
            }
          )).then(response => {
            if (response.payload === "") {
            message.success(form.getFieldValue('pay') > 0 ? "Users Paid" : "Users Deducted")
            }
            else {
              response.payload.forEach((error, index) => {
                message.error({
                  key: index,
                  content: error.message,
                  duration:15,
                  onClick:(() => message.destroy(index))
                });
              })
            }
            form.resetFields()
          })
        }
        else { //pay 1 student with custom
          dispatch(postTransaction(
            {
              amount: parseFloat(form.getFieldValue('pay')),
              description: form.getFieldValue('description'),
              owner_id: userData._id,
              student: row._id
            }
          )).then(response => {
            if (response.payload === "") {
            message.success(form.getFieldValue('pay') > 0 ? "User Paid" : "User Deducted")
            }
            else message.error(response.payload, 10)
            form.resetFields()
          })
        }
        visibleController()
      }
    }
  }
  
  const MakeForm = (localProps) => {
    const { visibleController, row } = localProps
    const [error, setError] = React.useState(true);
    const [form] = Form.useForm();

    const cancelClick = () => {
      visibleController()
      form.resetFields()
    }

    const onValuesChange = (values) => {
      const { pay, description } = form.getFieldsValue()
      let error1 = true
      let error2 = true
      if (pay === null || pay === undefined || pay === "" || isNaN(pay)) error1 = true
      else error1 = false
      if (description === null || description === undefined || description === "") error2 = true
      else error2 = false
      if (error1 || error2) setError(true)
      else setError(false)
    }
 
    return (
      <Form 
        form={form}
        onValuesChange={onValuesChange}
        initialValues={{
        pay: null,
        description: null
        }}
        >
        <FormItem 
          rules={[
            {
              required: true,
              message: "An integer must be entered",
              pattern: new RegExp(/^-?[0-9]\d*(\.\d+)?$/)
            },
          ]}
          name='pay'
        >
          <Input placeholder="Pay or Deduct" />
        </FormItem>
        <FormItem 
          rules={[
            {
              required: true,
              message: "A description must be entered",
            },
          ]}
          name='description'
        >
          <Input placeholder="Description" />
        </FormItem>
        <FormItem>
          <Button
            onClick={payClick(false, 0, row, form, visibleController)} 
            type={form.getFieldValue('pay') < 0 ? "danger" : "primary"} 
            htmlType="submit" 
            disabled={error}
          >
            {form.getFieldValue('pay') < 0 ? "Deduct" : "Pay"}
          </Button>
          <Button 
            onClick={cancelClick}
            >Cancel</Button>
        </FormItem>
      </Form>
    )
  }

  const PayComponent = (localProps) => {
    const { row } = localProps
    const [pop, setPop] = React.useState(false);

    const popVisibleController = () => {
      setPop(!pop)
    }

    return (
      <div className='payContainer' onClick={e => e.stopPropagation()}>
        <Popover 
          content={<MakeForm visibleController={popVisibleController} row={row} />}
          trigger="click"
          placement="left"
          visible={pop}
        >
            <Button className='customPayButton' onClick={popVisibleController} type="primary">{row._id === 'all' ? 'Pay/Deduct All' : 'Custom'}</Button>
        </Popover>
        <QuickPayComponent row={row} />
      </div>
    )
  }

  const KickComponent = (localProps) => {
    const { row } = localProps

    const kickClickHandler = (e, row) => {
      dispatch(kickUser({_id: classData._id, kick_id: row._id}))
      e.stopPropagation()
    }
    

    return (
      <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => kickClickHandler(e, row)} title="Are you sure you want to permanently kick from class?" okText="Yes" cancelText="No">
        <Button onClick={(e) => e.stopPropagation()} type="danger">Kick</Button>             
      </Popconfirm>
    )
  }

  const updatedRowData = members.map((row, index) => {
    let newRow = {...row}
    newRow.pay = <PayComponent id={index} row={row} />
    newRow.kick = <KickComponent id={index} row={row} />
    return newRow
  })

  const QuickPayComponent = (localProps) => {
    const { row } = localProps

    if (row._id === 'all') return null
    else {
      return (
        <div onClick={e => e.stopPropagation()}>
          <div className='quickPayContainer'>
            <Button className='customPayButton' onClick={payClick(true, 25, row, null, null)} type="primary">25</Button>
            <Button className='customPayButton' onClick={payClick(true, 100, row, null, null)} type="primary">100</Button>
          </div>
          <div className='quickPayContainer'>
            <Button className='customPayButton' onClick={payClick(true, -25, row, null, null)} type="danger">-25</Button>
            <Button className='customPayButton' onClick={payClick(true, -100, row, null, null)} type="danger">-100</Button>
          </div>
        </div>
      )
    } 
  }

  const configureTable = {
    onClick: (user) => {
      props.history.push("/student/users/" + user._id);
    },
    tableHeadings: [
      'rank', 'firstName', 'lastName', 'netWorth', 'pay', 'kick'
    ]
  }

  const regenClickHandler = () => {
    dispatch(regenCode({_id: classData._id})).then(response => {
      if (response.payload.addCode) {
        if (userData.role === 1) {
          message.success("Students can now register for the next 10 minutes")
          message.error("Beware of students trying to create multiple accounts", 15)
        }
      }
      else message.error("Your add Code is not regenerating")
    })
  }

  const path = props.location.pathname
  const n = path.lastIndexOf('/');
  const short_id = path.substring(n + 1);

  useEffect(() => {
    let dataToSubmit = {_id : short_id}
  	dispatch(selectClass(dataToSubmit))
  }, [dispatch, short_id])

  const onClick = (event) => {
    const mywindow = window.open("/teacher/class/picker/" + short_id, "mywindow", "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, width=230,height=200");
    mywindow.moveTo(0, 0);
  }
  
  return (
    <div className='classBody'>
      <Button onClick={onClick} className="picker flash" type="primary">
        <a className="flash">Student Picker</a>
      </Button>

      <div className='classInnerBody'>
        <div className='payBody'>
          <h1>Class: {classData.name}</h1>
          <h1>Code: {classData.addCode}</h1>
          <Button onClick={regenClickHandler} type="primary">Regen Code
            <Popover content={userData.role === 2 ? "Students can add with this code but not register" : "If your students can't register then regen your add code"}>
              <QuestionCircleTwoTone />
            </Popover>
          </Button>
          <PayComponent row = {{_id: 'all'}} />
        </div>
  	   <Table rowData={updatedRowData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(ClassPage))
// export default withRouter(UsersPage);